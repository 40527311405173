<template>
  <div class="tab-pane text-center active" id="pendingTab">
    <div>
      <base-header></base-header>
      <div class="mt-5 pt-4">
        <img src="assets/images/pendingicon.svg" alt="paymentPending" class="w-25">
      </div>
      <div class="px-3 pt-1">
        <div>
          <div>
            <h6 class="font-weight-bold mb-1">Pending Payment</h6>
            <p>Your payment is pending. Authorize this payment and click the button below</p>
          </div>
          <div class="d-flex flex-column-reverse mt-4" style="gap: 10px;">
            <base-check-payment-status></base-check-payment-status>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BaseHeader from '@/components/ui/BaseHeader';
import BaseCheckPaymentStatus from '@/components/ui/BaseCheckPaymentStatus';

export default {
  name: 'BasePaymentPending',
  components: { BaseCheckPaymentStatus, BaseHeader },
  data () {
    return {
      questions: ['I did not receive a payment prompt', 'I have been debited but sale is still pending']
    };
  }
};
</script>

<style scoped>

</style>
