import httpClient from '@/api/httpClient';

const BASE_URL = 'https://precheckout.hubtel.com/api/cybersource';

const send3DSetupRequest = (payload) => httpClient.post(`${BASE_URL}/3ds-setup`, payload);

const send3DEnrollRequest = (payload) => httpClient.post(`${BASE_URL}/3ds-enroll`, payload);

export {
  send3DSetupRequest,
  send3DEnrollRequest
};
