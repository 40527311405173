<template>
  <teleport to="body">
    <div class="modal-backdrop fade show" @click="$emit('close')" v-if="closeOnBackDropClicked"></div>
    <div class="modal-backdrop fade show" v-else></div>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" aria-modal="true" >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" class="close" @click="$emit('close')" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <div class="modal-body py-4 px-4 px-lg-5">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    closeOnBackDropClicked: {
      type: Boolean,
      required: false,
      default: true
    },
    wider: {
      type: Boolean,
      default: true
    }
  },
  emits: ['close']
};
</script>

<style scoped>

</style>
