<template>
  <base-modal v-if="modalConfig.visible" @close="closeModal" :close-on-back-drop-clicked="false">
 <div class="text-center">
   <div class="mb-3">
     <img src="assets/images/failed.png" alt="" class="mt-3" width="90">
   </div>
   <div>
     <p> {{ modalConfig.message }}
     </p>
   </div>
   <div class="mt-4">
     <a role="button" class="btn btn-outline-primary" @click="closeModal">Close</a>
   </div>
 </div>
  </base-modal>
  <base-blacklisted-card v-if="isCustomerBlacklisted" :extra-data="extraData"></base-blacklisted-card>
  <div class="wrapper wrapper--no-sidebar" v-else>
    <div class="vh-100">
      <div class="row no-gutters h-100 justify-content-center align-items-md-center">
        <div class="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-8">
          <div class="row justify-content-center no-gutters">
            <left-sidebar :show-loader="showLoader" :extra-data="extraData"></left-sidebar>
            <right-sidebar :show-loader="showLoader" :current-component="currentComponent"></right-sidebar>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/components/ui/BaseModal';
import BaseBlacklistedCard from '@/components/ui/BaseBlacklistedCard';
import LeftSidebar from '@/components/layout/LeftSidebar';
import RightSidebar from '@/components/layout/RightSidebar';
import { setMixPanelData } from '@/utils';
export default {
  name: 'Index',
  inject: ['logger'],
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showLoader: true
    };
  },
  components: {
    LeftSidebar,
    RightSidebar,
    BaseBlacklistedCard,
    BaseModal
  },
  computed: {
    ...mapGetters({
      currentComponent: 'currentComponent',
      order: 'storeModule/order',
      businessInfo: 'storeModule/businessInfo',
      isCustomerBlacklisted: 'storeModule/isCustomerBlacklisted',
      isCustomerTrusted: 'storeModule/isCustomerTrusted',
      modalConfig: 'modalConfig',
      currentPaymentChannel: 'currentPaymentChannel'
    }),
    extraData () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.extraData.paylinkUrl ?? '',
        customerName: this.extraData.customerName ?? '',
        title: this.extraData.paylinkTitle ?? '',
        description: this.extraData.paylinkDescription ?? '',
        phoneNumber: this.extraData.consumerSettlementNumber ?? this.extraData.customerMobileNumber,
        linkType: this.extraData.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.extraData.hasIdentifier ?? '',
        source: this.extraData.source ?? ''
      };
    }
  },
  methods: {
    ...mapActions({
      getStoreDetails: 'storeModule/getStoreDetails',
      changeComponent: 'changeComponent'
    }),
    closeModal () {
      this.$store.commit('setModalConfig', {
        visible: false,
        message: ''
      });
    }
  },
  async created () {
    this.logger.debug(this.orderId, 'Saved Order ID');
    if (this.orderId) {
      try {
        await this.getStoreDetails(this.orderId);
        this.$store.commit('setOrderId', this.orderId);
        this.logger.debug(this.isCustomerBlacklisted, 'isCustomerBlacklisted');
        this.showLoader = false;
        if (this.isCustomerBlacklisted) {
          setMixPanelData(this.mixPanelData, 'user_blacklisted', 'checkout');
          this.changeComponent('BaseBlacklistedCard');
        } else {
          setMixPanelData(this.mixPanelData, 'page', 'view');
          setMixPanelData(this.mixPanelData, 'begin_checkout', 'checkout');
          setMixPanelData(this.mixPanelData, 'order_created', 'checkout');
          this.changeComponent('BasePaymentChannels');
        }
      } catch (e) {
        this.showLoader = false;
        setMixPanelData(this.mixPanelData, 'order_creation_failed', 'checkout');
        this.changeComponent('BaseBlankCard');
        const payload = {
          order: null,
          businessInfo: null,
          isCustomerTrusted: false,
          isCustomerBlacklisted: false
        };
        this.$store.commit('storeModule/setStoreDetails', payload);
      }
    }
  }

};
</script>

<style scoped>

</style>
