import { addSupportFeedback } from '@/api/support';

const supportModule = {
  namespaced: true,
  state () {
    return {

    };
  },
  actions: {
    async addSupportFeedback ({ commit }, payload) {
      return await addSupportFeedback(payload.orderId, payload.data);
    }
  }
};

export default supportModule;
