<template>
  <layout-dialog v-if="showDialog" @close="closeModal">
  <PCIContent />
  </layout-dialog>
  <layout-dialog v-if="showVerificationDialog" @close="closeVerificationModal">
    <verification-content :extra-data="extraData"></verification-content>
  </layout-dialog>
  <left-side-bar-loader v-if="showLoader"></left-side-bar-loader>
  <div class="col-12 col-md-12 col-lg-6 col-xl-6 d-none d-lg-block pr-md-1" v-else-if="!showLoader && !isEmpty">
    <div class="bg-white h-100">
      <div class="p-md-5">
        <div>
          <div class="mb-4">
            <div class="d-flex align-items-center">
              <div>
                <img  :src="extraData.logoUrl" alt="pay-link-logo" class="rounded-circle object-fit-contain" width="75" height="75">
              </div>
              <div class="mx-3" style="border-right: 1px solid #B7B7B7;height: 70px;"></div>
              <div class="">
                <h6 class="font-weight-bold">{{ extraData.paylinkTitle }}</h6>
              </div>
            </div>
          </div>
          <div class="" style="height:395px">
            <div class="py-4 border-bottom">
              <h6 class="font-weight-bold">Description</h6>
              <div id="scroll" style="max-height: 70px;">
                <h6 class="mb-1">{{ extraData.paylinkDescription }}</h6>
              </div>
            </div>
            <div class="py-4 border-bottom text-center">
              <h6 class="mb-2 font-weight-bold d-flex align-items-center justify-content-center">
                <img src="/assets/images/twitter-check.svg" alt="check" class="mr-1" width="13" v-if="isCustomerTrusted">
                {{ extraData.customerName }}
              </h6>
              <h6 class="mb-2">
                {{ extraData.customerMobileNumber }}
              </h6>
              <p @click="openVerificationDialog" class="small mb-2 pointer">
                This user has been verified. <span class="text-primary">See all verification details</span>
              </p>
            </div>
            <div class="border-bottom py-4">
              <div class="d-flex align-items-center my-2">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" :class="extraData.isPartialPay ? null : 'svg-disabled'" class="mr-3" style="fill: black;" width="25" height="25" viewBox="0 0 30 30">
                    <g id="Group_38244" data-name="Group 38244" transform="translate(0 0.1)">
                      <circle id="Ellipse_2003" data-name="Ellipse 2003" cx="15" cy="15" r="15" transform="translate(0 -0.1)" fill="#ecfffd"></circle>
                      <g id="pay" transform="translate(5.793 6.737)">
                        <path id="Path_32310" data-name="Path 32310" d="M78.841,320.952l-.749-.449a1.474,1.474,0,0,0-.756-.209H76.21a2.666,2.666,0,0,0-.684.088l-3.4.912a1.184,1.184,0,0,0-.8.742l-2.987-1.616a1.171,1.171,0,0,0-.8-.132,1.106,1.106,0,0,0-.3.1,1.156,1.156,0,0,0-.505.471,1.179,1.179,0,0,0-.156.585,1.165,1.165,0,0,0,.581,1.017l5.354,3.2a1.47,1.47,0,0,0,.774.22h4.144a1.468,1.468,0,0,0,.54-.1l.863-.341v.444h2.941V320H78.841Zm-1.079,4.28a.884.884,0,0,1-.324.062H73.295a.885.885,0,0,1-.469-.135l-5.361-3.207a.585.585,0,0,1-.292-.511.6.6,0,0,1,.079-.294.578.578,0,0,1,.253-.233.556.556,0,0,1,.147-.048.621.621,0,0,1,.113-.01.577.577,0,0,1,.288.076l3.239,1.75c0,.018,0,.036.008.054a1.179,1.179,0,0,0,1.137.871,2.9,2.9,0,0,0,.485-.086l3.055-.818-.152-.568-3.045.814c-.134.031-.307.066-.343.069a.588.588,0,0,1-.568-.433.537.537,0,0,1-.017-.109s0-.071,0-.1a.594.594,0,0,1,.433-.519l3.395-.908a2.054,2.054,0,0,1,.531-.07h1.127a.882.882,0,0,1,.453.125l1.051.629v3.168Zm1.667-4.644h1.764v4.705H79.43Zm0,0" transform="translate(-64.137 -308.237)" fill="#01c7b1"></path>
                        <path id="Path_32311" data-name="Path 32311" d="M12.129,2.352,10.552.775A2.628,2.628,0,0,0,8.68,0h-3.7a1.48,1.48,0,0,0-.658.155l-.659.33A.872.872,0,0,1,3.24.577l-.3-.01V0H0V6.47H2.941V5.587h.5l.178.118a3.242,3.242,0,0,0,1.675.539V10H17.645V2.352ZM2.353,5.881H.588V.588H2.353Zm1.592-.666L3.617,5H2.941V1.155l.277.01a1.476,1.476,0,0,0,.711-.154l.659-.33a.884.884,0,0,1,.4-.093h3.7a2.045,2.045,0,0,1,1.456.6L11.3,2.352H8.871l-.714-.714-.416.416L9.818,4.132a.59.59,0,1,1-.832.836L7.251,3.233l-.206.176a1.559,1.559,0,0,1-1.982.029L4.893,3.3l-.371.457.172.14a2.144,2.144,0,0,0,.6.338V5.655a2.652,2.652,0,0,1-1.349-.44ZM8.127,4.94l.444.444a1.185,1.185,0,0,0,1.662,0,1.489,1.489,0,1,0,.167-.213,1.157,1.157,0,0,0,.1-1.055h3.938A1.769,1.769,0,0,0,15.88,5.563V6.788a1.769,1.769,0,0,0-1.446,1.446H8.5A1.769,1.769,0,0,0,7.058,6.788V5.563a1.756,1.756,0,0,0,1.07-.623Zm2.459,1.235a.882.882,0,1,1,.882.882A.882.882,0,0,1,10.587,6.175Zm6.47,3.235H5.882V4.358A2.138,2.138,0,0,0,7.2,4.02l.5.5A1.173,1.173,0,0,1,6.764,5H6.47V7.352h.294A1.176,1.176,0,0,1,7.94,8.528v.294H15V8.528a1.176,1.176,0,0,1,1.176-1.176h.294V5h-.294A1.176,1.176,0,0,1,15,3.823V3.529H10.047l-.588-.588h7.6Zm0,0" transform="translate(0 0)" fill="#01c7b1"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <p class="m-0 text-black" :class="extraData.isPartialPay ? null: 'options-disabled'">
                  You can pay in installments
                </p>
              </div>
              <div class="d-flex align-items-center my-2">
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" :class="extraData.scenarioType === 'Multiple_Private_Open' ? null : 'svg-disabled'" class="mr-3" style="fill: black;" width="25" height="25" viewBox="0 0 30 30">
                    <g id="Group_38244" data-name="Group 38244" transform="translate(0 0.1)">
                      <circle id="Ellipse_2003" data-name="Ellipse 2003" cx="15" cy="15" r="15" transform="translate(0 -0.1)" fill="#ecfffd"></circle>
                      <g id="pay" transform="translate(5.793 6.737)">
                        <path id="Path_32310" data-name="Path 32310" d="M78.841,320.952l-.749-.449a1.474,1.474,0,0,0-.756-.209H76.21a2.666,2.666,0,0,0-.684.088l-3.4.912a1.184,1.184,0,0,0-.8.742l-2.987-1.616a1.171,1.171,0,0,0-.8-.132,1.106,1.106,0,0,0-.3.1,1.156,1.156,0,0,0-.505.471,1.179,1.179,0,0,0-.156.585,1.165,1.165,0,0,0,.581,1.017l5.354,3.2a1.47,1.47,0,0,0,.774.22h4.144a1.468,1.468,0,0,0,.54-.1l.863-.341v.444h2.941V320H78.841Zm-1.079,4.28a.884.884,0,0,1-.324.062H73.295a.885.885,0,0,1-.469-.135l-5.361-3.207a.585.585,0,0,1-.292-.511.6.6,0,0,1,.079-.294.578.578,0,0,1,.253-.233.556.556,0,0,1,.147-.048.621.621,0,0,1,.113-.01.577.577,0,0,1,.288.076l3.239,1.75c0,.018,0,.036.008.054a1.179,1.179,0,0,0,1.137.871,2.9,2.9,0,0,0,.485-.086l3.055-.818-.152-.568-3.045.814c-.134.031-.307.066-.343.069a.588.588,0,0,1-.568-.433.537.537,0,0,1-.017-.109s0-.071,0-.1a.594.594,0,0,1,.433-.519l3.395-.908a2.054,2.054,0,0,1,.531-.07h1.127a.882.882,0,0,1,.453.125l1.051.629v3.168Zm1.667-4.644h1.764v4.705H79.43Zm0,0" transform="translate(-64.137 -308.237)" fill="#01c7b1"></path>
                        <path id="Path_32311" data-name="Path 32311" d="M12.129,2.352,10.552.775A2.628,2.628,0,0,0,8.68,0h-3.7a1.48,1.48,0,0,0-.658.155l-.659.33A.872.872,0,0,1,3.24.577l-.3-.01V0H0V6.47H2.941V5.587h.5l.178.118a3.242,3.242,0,0,0,1.675.539V10H17.645V2.352ZM2.353,5.881H.588V.588H2.353Zm1.592-.666L3.617,5H2.941V1.155l.277.01a1.476,1.476,0,0,0,.711-.154l.659-.33a.884.884,0,0,1,.4-.093h3.7a2.045,2.045,0,0,1,1.456.6L11.3,2.352H8.871l-.714-.714-.416.416L9.818,4.132a.59.59,0,1,1-.832.836L7.251,3.233l-.206.176a1.559,1.559,0,0,1-1.982.029L4.893,3.3l-.371.457.172.14a2.144,2.144,0,0,0,.6.338V5.655a2.652,2.652,0,0,1-1.349-.44ZM8.127,4.94l.444.444a1.185,1.185,0,0,0,1.662,0,1.489,1.489,0,1,0,.167-.213,1.157,1.157,0,0,0,.1-1.055h3.938A1.769,1.769,0,0,0,15.88,5.563V6.788a1.769,1.769,0,0,0-1.446,1.446H8.5A1.769,1.769,0,0,0,7.058,6.788V5.563a1.756,1.756,0,0,0,1.07-.623Zm2.459,1.235a.882.882,0,1,1,.882.882A.882.882,0,0,1,10.587,6.175Zm6.47,3.235H5.882V4.358A2.138,2.138,0,0,0,7.2,4.02l.5.5A1.173,1.173,0,0,1,6.764,5H6.47V7.352h.294A1.176,1.176,0,0,1,7.94,8.528v.294H15V8.528a1.176,1.176,0,0,1,1.176-1.176h.294V5h-.294A1.176,1.176,0,0,1,15,3.823V3.529H10.047l-.588-.588h7.6Zm0,0" transform="translate(0 0)" fill="#01c7b1"></path>
                      </g>
                    </g>
                  </svg>
                </div>
                <p class="m-0 text-black" :class="extraData.scenarioType === 'Multiple_Private_Open' ? null : 'options-disabled'">
                  You may view a list of who has paid from your receipt
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center pt-5 mt-4">
            <div class="d-flex">
              <img src="/assets/images/cash.svg" alt="cash" class="mr-2" width="25">
              <p class="m-0">Your money is safe</p>
            </div>
            <div>
              <a role="button" @click="openDialog" class="text-primary ml-5 d-flex align-items-center text-decoration-none pointer"><span>See more</span>
                <i class="fas fa-angle-right ml-2 text-primary"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-12 col-lg-6 col-xl-6 d-none d-lg-block pr-md-1" v-else>
    <div class="bg-white h-100">
      <div class="p-md-5">
        <div>
          <div class="" style="height:395px">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutDialog from '@/components/layout/LayoutDialog';
import LeftSideBarLoader from '@/components/layout/LeftSideBarLoader';
import PCIContent from '@/components/layout/PCIContent';
import VerificationContent from '@/components/layout/VerificationContent';
import { mapGetters } from 'vuex';

export default {
  name: 'LeftSidebar',
  components: { VerificationContent, PCIContent, LeftSideBarLoader, LayoutDialog },
  props: {
    extraData: {
      type: Object,
      required: true
    },
    showLoader: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isEmpty () {
      return Object.keys(this.extraData).length === 0 && !this.order;
    },
    ...mapGetters({
      order: 'storeModule/order',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    })
  },
  data () {
    return {
      showDialog: false,
      showVerificationDialog: false
    };
  },
  methods: {
    closeModal (payload) {
      this.showDialog = payload;
    },
    openDialog () {
      this.showDialog = true;
    },
    closeVerificationModal (payload) {
      this.showVerificationDialog = payload;
    },
    openVerificationDialog () {
      this.showVerificationDialog = true;
    }
  }
};
</script>
