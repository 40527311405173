<template>
  <base-modal v-if="showDialog">
    <base-loader v-if="isSubmiting"></base-loader>
    <div>
      <div>
        <p class="my-1">Complete your card details</p>
      </div>
      <div class="credit-card-details mt-4">
        <form action="" id="payment-form">
          <div class="form-group">
            <label class="small font-weight-bold">Card Number</label>
            <div class="row no-gutters">
              <div class="col-4 d-flex align-items-center">
                <input type="text" :value="cardNumberDisplayFirst6" placeholder="4444" class="filled-6 py-1 text-center form-control py-3" disabled="">
                <span class="font-weight-bold p-2 text-muted">-</span>
              </div>
              <div class="col-5 d-flex align-items-center">
                <input type="tel" placeholder="***-***" class="filled-6 py-1 text-center form-control py-3 card-val bg-white" formControlName="creditCard" maxlength="6"  v-model="v$.missingCardNumber.$model"  @input="onlyNumber">
                <span class="font-weight-bold p-2 text-muted">-</span>
              </div>
              <div class="col-3 d-flex align-items-center">
                <input type="text" :value="cardNumberDisplayLast4" placeholder="4444" class="filled-6 py-1 form-control py-3 text-center" disabled="">
              </div>
            </div>
            <span class="text-danger d-flex justify-content-center" v-if="v$.missingCardNumber.required.$invalid && v$.missingCardNumber.$dirty">A valid card number is required</span>
            <span class="text-danger d-flex justify-content-center" v-if="v$.missingCardNumber.minLength.$invalid">A valid card number must be {{ v$.missingCardNumber.minLength.$params.min }} numbers.</span>
          </div>
          <div class="row">
            <div class="col-6 col-md-6">
              <div class="form-group">
                <label  class="small font-weight-bold">Expiration</label>
                <input type="text" placeholder="12/21" name="cardExpiry" class="filled-6 py-1 form-control py-3 bg-white" v-model="cardExpiryDisplay" ref="cardExpiryInput">
              </div>
            </div>
            <div class="col-6 col-md-6">
              <div class="form-group">
                <label class="small font-weight-bold">CVV</label>
                <input type="text" placeholder="CVV" name="cardCVV" class="filled-6 py-1 form-control py-3 bg-white" v-model="v$.cvvNumber.$model" ref="cardCvvInput" @input="onlyNumber" maxlength="4">
                <span class="text-danger" v-if="v$.cvvNumber.required.$invalid && v$.cvvNumber.$dirty">A valid cvv number is required</span>
                <span class="text-danger" v-if="v$.cvvNumber.minLength.$invalid">cvv must have at least {{ v$.cvvNumber.minLength.$params.min }} numbers.</span>
              </div>
            </div>
          </div>
          <div class="mt-4 text-left d-flex flex-column-reverse" style="gap: 10px;">
            <a role="button" @click="closeModal()" class="text-center mt-2 font-weight-bold text-uppercase">Cancel</a>
            <button :disabled="disableConfirmAndPayButton" @click="payWithCard()" class="btn btn-block btn-primary text-uppercase font-weight-bold py-3" type="button">Confirm & Pay</button>
          </div>
        </form>
      </div>
    </div>
  </base-modal>
  <base-loader v-if="showLoader"></base-loader>
  <div class="tab-pane active" id="cardOptions">
    <div>
      <div class="pb-4 mb-2">
        <base-header></base-header>
      </div>
      <div class="">
        <h6 class="font-weight-bold m-0">How do you want to pay?</h6>
      </div>
      <div>
        <div class="">
          <base-slick-card @card-select="onCardSelected($event)"></base-slick-card>
          <div class="mt-3">
            <div class="d-flex flex-column-reverse" style="gap: 10px;">
              <a role="button" @click="handleChangeComponent('BasePaymentChannels')" class="text-center mt-2 font-weight-bold text-uppercase">Back</a>
              <button  @click="confirmAndPay(activeWallet.type, activeWallet.provider, activeWallet.providerType)" type="button" class="btn btn-primary text-uppercase font-weight-bold py-3">pay {{businessInfo.currency}} {{$filters.formatAmount(total)}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSlickCard from '@/components/ui/BaseSlickCard';
import BaseLoader from '@/components/ui/BaseLoader';
import { mapActions, mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import BaseHeader from '@/components/ui/BaseHeader';
import BaseModal from '@/components/ui/BaseModal';
import { setMixPanelData } from '@/utils';

export default {
  name: 'CustomerWallets',
  components: { BaseModal, BaseHeader, BaseSlickCard, BaseLoader },
  setup () {
    return { v$: useVuelidate() };
  },
  data () {
    return {
      activeWallet: null,
      missingCardNumber: '',
      showLoader: false,
      isSubmiting: false,
      cvvNumber: '',
      scheme: '',
      showDialog: false,
      questions: ['I\'m not able to submit payment request', 'My card payment is failing', 'My Hubtel payment is failing']
    };
  },
  validations () {
    return {
      missingCardNumber: {
        required,
        minLength: minLength(6)
      },
      cvvNumber: {
        required,
        minLength: minLength(3)
      }
    };
  },
  watch: {
    missingCardNumber (newValue) {
      if (newValue.length === 6 && this.cardExpiryDisplay) {
        this.$nextTick(() => this.$refs.cardCvvInput.focus());
      }
      if (newValue.length === 6 && !this.cardExpiryDisplay) {
        this.$nextTick(() => this.$refs.cardExpiryInput.focus());
      }
    }
  },
  computed: {
    ...mapGetters({
      businessInfo: 'storeModule/businessInfo',
      order: 'storeModule/order',
      customerData: 'customersModule/customerData',
      charge: 'chargeModule/getCharge',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    }),
    cardNumberDisplayFirst6 () {
      const cardNumberData = this.activeWallet?.accountNo;
      if (!cardNumberData) {
        return '';
      }
      return cardNumberData.substr(0, 4) + '-' + cardNumberData.substr(4, 2);
    },
    cardNumberDisplayLast4 () {
      const cardNumberData = this.activeWallet?.accountNo;
      if (!cardNumberData) {
        return '';
      }

      return cardNumberData.substr(12, 4);
    },
    cardExpiryDisplay () {
      if (!this.activeWallet?.expiry) {
        return '';
      }
      return this.activeWallet?.expiry.replace('/', ' / ');
    },
    disableConfirmAndPayButton () {
      if (this.activeWallet?.type && (this.activeWallet?.type.toLowerCase() === 'momo' || this.activeWallet?.type.toLowerCase() === 'hubtel')) {
        return false;
      }
      return !(this.activeWallet?.type && this.activeWallet?.type.toLowerCase() === 'card' && this.missingCardNumber && this.missingCardNumber.length === 6 && this.cvvNumber && this.cvvNumber.length >= 3);
    },
    total () {
      if (!this.order) { return 0; }
      return Number(this.order.totalAmountDue) + Number(this.charge);
    },
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.paylinkDetails.paylinkUrl ?? '',
        customerName: this.paylinkDetails.customerName ?? '',
        title: this.paylinkDetails.paylinkTitle ?? '',
        description: this.paylinkDetails.paylinkDescription ?? '',
        phoneNumber: this.paylinkDetails.consumerSettlementNumber ?? this.paylinkDetails.customerMobileNumber,
        linkType: this.paylinkDetails.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.paylinkDetails.hasIdentifier ?? '',
        source: this.paylinkDetails.source ?? ''
      };
    }
  },
  methods: {
    ...mapActions({
      chargeHubtelWallet: 'paymentModule/chargeHubtelWallet',
      chargeMobileMoneyWallet: 'paymentModule/chargeMobileMoneyWallet',
      chargeCardWallet: 'paymentModule/chargeCardWallet',
      changeComponent: 'changeComponent'
    }),
    onCardSelected (wallet) {
      this.activeWallet = wallet;
      this.missingCardNumber = '';
      this.cvvNumber = '';
      this.$nextTick(() => this.v$.$reset());
    },
    async confirmAndPay (type, provider, scheme) {
      switch (type.toLowerCase()) {
        case 'hubtel':
          await this.payWithHubtel(provider);
          break;
        case 'momo':
          if (['mtn', 'tigo', 'vodafone'].includes(provider.toLowerCase())) {
            return await this.payWithMobileMoney(provider);
          }
          break;
        case 'card':
          if (['mastercard', 'visa'].includes(scheme.toLowerCase())) {
            return this.openDialog(scheme);
          }
          break;
      }
    },
    async payWithHubtel (provider) {
      try {
        this.showLoader = true;
        const payload = {
          country: this.businessInfo.country,
          provider,
          orderId: this.order.id,
          data: {
            customerName: this.customerData.fullName,
            phoneNumber: this.customerData.mobileNumber,
            customerReward: 0
          }
        };
        await this.chargeHubtelWallet(payload);
        this.showLoader = false;
        this.changeComponent('BasePaymentPending');
      } catch (e) {
        this.showLoader = false;
      }
    },
    async payWithMobileMoney (provider) {
      try {
        this.showLoader = true;
        const payload = {
          country: this.businessInfo.country,
          network: provider,
          orderId: this.order.id,
          data: {
            phoneNumber: this.customerData.mobileNumber,
            customerReward: 0
          }
        };
        await this.chargeMobileMoneyWallet(payload);
        this.showLoader = false;
        this.changeComponent('BasePaymentPending');
      } catch (e) {
        this.showLoader = false;
      }
    },
    async payWithCard () {
      try {
        this.isSubmiting = true;
        const [month, year] = this.activeWallet?.expiry.split('/');
        const payload = {
          country: this.businessInfo.country,
          scheme: this.scheme,
          orderId: this.order.id,
          data: {
            cardHolderName: this.activeWallet.accountName,
            cardNumber: this.missingCardNumber,
            cvv: this.cvvNumber,
            expiryMonth: month,
            expiryYear: year,
            customerPhoneNumber: this.customerData.mobileNumber,
            customerReward: 0,
            issuer: this.activeWallet.providerType,
            walletId: this.activeWallet.id
          }
        };
        await this.chargeCardWallet(payload);
        this.isSubmiting = false;
        this.closeModal();
        this.changeComponent('BasePaymentPending');
      } catch (e) {
        this.isSubmiting = false;
        this.closeModal();
      }
    },
    onlyNumber () {
      this.missingCardNumber = this.missingCardNumber.replace(
        /[^0-9]/g,
        ''
      );
      this.cvvNumber = this.cvvNumber.replace(
        /[^0-9]/g,
        ''
      );
    },
    closeModal () {
      this.showDialog = false;
      this.scheme = '';
    },
    openDialog (scheme) {
      this.scheme = scheme;
      this.showDialog = true;
    },
    handleChangeComponent (component) {
      setMixPanelData(this.mixPanelData, 'order_cancelled', 'checkout', { walletType: 'hubtel', walletChannel: 'hubtel' });
      this.changeComponent(component);
    }
  }
};
</script>

<style scoped>

</style>
