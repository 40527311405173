<template>
  <div class="col-12 col-md-12 col-lg-6 col-xl-6 d-none d-lg-block pr-md-1">
    <div class="bg-white h-100">
      <div class="p-md-5">
        <div>
          <div class="mb-4">
            <div class="d-flex align-items-center">
              <div>
                <p class="animated-background placeholder-title rounded" style="height: 70px; width: 90px; margin-top: 10px;"></p>
              </div>
              <div class="mx-3" style="border-right: 1px solid #B7B7B7;height: 70px;"></div>
              <div class="pt-3">
                <p class="animated-background placeholder-title rounded" style="height: 16px; width: 215px" v-for="num in 3" :key="num"></p>
              </div>
            </div>
          </div>
          <div  style="height:367px">
            <div class="pt-3">
              <p class="animated-background placeholder-title rounded" style="height: 46px;"></p>
              <p class="animated-background placeholder-title rounded" style="height: 46px;"></p>
              <p class="animated-background placeholder-title rounded" style="height: 46px;"></p>
            </div>
            <div class="pt-5 mt-3">
              <div class="d-flex align-items-center my-2"  v-for="num in 2" :key="num">
                <div>
                  <p class="animated-background placeholder-title rounded" style="height: 20px; width: 15px; margin-right: 1rem" ></p>
                </div>
                <p class="animated-background placeholder-title rounded" style="height: 16px; width: 215px" ></p>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center pt-3 mt-4">
            <div class="d-flex">
              <p class="animated-background placeholder-title rounded" style="height: 20px; width: 15px; margin-right: 1rem" ></p>
              <p class="animated-background placeholder-title rounded" style="height: 20px; width: 115px;" ></p>
            </div>
            <div>
              <p class="animated-background placeholder-title rounded" style="height: 20px; width: 115px; margin-left: 3rem" ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftSideBarLoader'
};
</script>

<style scoped>
.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #e1e4ea 10%, #dde0e6 18%, #e1e4ea 33%);
  background-size: 800px 104px;
  position: relative;
}
</style>
