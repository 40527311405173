import httpClient from '@/api/httpClient';

const BASE_URL = 'https://payapi.hubtel.com/api';

const sendFraudLabRequest = (payload) => httpClient.post(`${BASE_URL}/fraudLabs`, payload);
const checkFraudLabStatus = (requestId) => httpClient.get(`${BASE_URL}/fraudLabs/request/status?requestId=${requestId}`);
const send3dsEnrollmentRequest = (payload) => httpClient.post(`${BASE_URL}/CyberSource/validate-3ds-enrollment`, payload);

export {
  sendFraudLabRequest,
  checkFraudLabStatus,
  send3dsEnrollmentRequest
};
