<template>
  <div class="px-2 py-4 px-lg-5 mx-lg-2">
    <div class="text-center">
      <p class="m-0 font-weight-bold d-flex align-items-center justify-content-center">
        <img src="/assets/images/twitter-check.svg" alt="check" class="mr-1" width="13" v-if="isCustomerTrusted">
        {{ extraData.customerName }}
      </p>
    </div>
    <div class="mt-4">
      <div>
        <p class="small text-blue font-weight-bold m-0">
          <i class="fas fa-user"></i>
          Verification Details
        </p>
        <div class="row">
          <div class="col-7">
            <p class="m-0">Hubtel Account Number:</p>
            <p class="m-0">Verification ID:</p>
          </div>
          <div class="col-5">
            <p class="m-0">{{extraData.customerVerificationData?.hubtelAccountNumber}}</p>
            <p class="m-0 text-capitalize">{{extraData.customerVerificationData?.verificationId}}</p>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <p class="small text-blue m-0 font-weight-bold">
          <i class="fas fa-university"></i>
          Beneficiary Account Details
        </p>
        <div class="row">
          <div class="col-7">
            <p class="m-0">Account Type:</p>
            <p class="m-0">Provider:</p>
            <p class="m-0">Account Number:</p>
            <p class="m-0">Name on Account:</p>
          </div>
          <div class="col-5">
            <p class="m-0 text-capitalize">{{extraData.customerVerificationData?.accountType}}</p>
            <p class="m-0 text-capitalize">{{extraData.customerVerificationData?.provider}}</p>
            <p class="m-0">{{extraData.customerVerificationData?.accountNumber}}</p>
            <p class="m-0 text-capitalize">{{extraData.customerVerificationData?.nameOnAccount}}</p>
          </div>
        </div>
        <p class="text-muted small">(Your payment will be credited instantly to this account)</p>
      </div>
      <p class="small mt-4 mb-0 text-center">
        <i class="fas fa-lock text-primary"></i>
        Verified by <a href="https://hubtel.com" class="text-decoration-none" target="”_blank”">Hubtel</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'VerificationContent',
  props: {
    extraData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    })
  }
};
</script>

<style scoped>

</style>
