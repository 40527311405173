<template>
  <base-loader v-if="showLoader"></base-loader>
  <div class="tab-pane pt-lg-5 active" id="paymentOptions">
    <div>
      <base-header></base-header>
      <div class="p-3">
        <div class="my-4" v-if="businessInfo?.paymentChannels.length">
          <p class="font-weight-bold">How do you want to pay?</p>
        </div>
        <div class="my-4" v-else>
          <p class="font-weight-bold">No payment channel is available.</p>
        </div>
        <div>
          <div class="border-bottom-muted mb-3">
            <a role="button" @click="checkIsTrustedChannel" v-if="showMobileMoneyChannel"  class="text-body text-decoration-none my-2">
              <div class="d-flex justify-content-between align-items-center my-3">
                <div class="d-flex align-items-center">
                  <div>
                    <img src="/assets/images/momoImg.png" alt="network-logos" class="mr-2" width="25">
                  </div>
                  <div>
                    <p class="m-0">Mobile Money</p>
                    <p class="m-0 small text-muted d-none d-md-block">MTN, At, Vodafone</p>
                  </div>
                </div>
                <div>
                  <i class="fas fa-caret-right ml-2"></i>
                </div>
              </div>
            </a>
          </div>
          <div class="border-bottom-muted mb-3">
            <a role="button" @click="handleChangeComponent('BankCardChannel', { walletType: 'card', walletChannel: 'card' })" v-if="showBankCardChannel" class="text-body text-decoration-none">
              <div class="d-flex justify-content-between align-items-center my-3">
                <div class="d-flex align-items-center">
                  <div>
                    <img src="/assets/images/credit-card.png" alt="cards" class="mr-2" width="25">
                  </div>
                  <div>
                    <p class="m-0">Bank Cards</p>
                    <p class="m-0 small text-muted d-none d-md-block">MasterCard and VISA only</p>
                  </div>
                </div>
                <div>
                  <i class="fas fa-caret-right ml-2"></i>
                </div>
              </div>
            </a>
          </div>
          <div class="border-bottom-muted mb-3 d-none d-lg-block">
            <a role="button" @click="handleChangeComponent('GhQrChannel', { walletType: 'ghqr', walletChannel: 'ghqr' })" v-if="showGhQrChannel" class="text-body text-decoration-none">
              <div class="d-flex justify-content-between align-items-center my-3">
                <div class="d-flex align-items-center">
                  <div>
                    <img src="/assets/images/ghqr-small.png" alt="ghqr" class="mr-2" width="25">
                  </div>
                  <div>
                    <p class="m-0">GhQR</p>
                    <p class="m-0 small text-muted d-none d-md-block">Scan and pay with your bank app</p>
                  </div>

                </div>
                <div>
                  <i class="fas fa-caret-right ml-2"></i>
                </div>
              </div>
            </a>
          </div>
          <div class="border-bottom-muted mb-3">
            <a role="button" @click="handleChangeComponent('HubtelChannel', { walletType: 'hubtel', walletChannel: 'hubtel' })" v-if="showHubtelChannel" class="text-body text-decoration-none">
              <div class="d-flex justify-content-between align-items-center my-3">
                <div class="d-flex align-items-center">
                  <div>
                    <img src="/assets/images/hubtel.png" alt="hubtel-logo" class="mr-2" width="25">
                  </div>
                  <div>
                    <p class="m-0">Hubtel</p>
                    <p class="m-0 small text-muted d-none d-md-block">Scan and Pay with the Hubtel account</p>
                  </div>
                </div>
                <div>
                  <i class="fas fa-caret-right ml-2"></i>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="mt-4 pt-2 pt-lg-0 text-center">
          <a role="button" @click="redirect" class="font-weight-bold text-uppercase">Back</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoader from '@/components/ui/BaseLoader';
import BaseHeader from '@/components/ui/BaseHeader';
import { setMixPanelData } from '@/utils';
export default {
  name: 'BasePaymentChannels',
  components: { BaseHeader, BaseLoader },
  data () {
    return {
      showLoader: false,
      countDown: 0
    };
  },
  computed: {
    ...mapGetters({
      orderId: 'orderId',
      businessInfo: 'storeModule/businessInfo',
      order: 'storeModule/order',
      charge: 'chargeModule/getCharge',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    }),
    total () {
      if (!this.order) { return 0; }
      return Number(this.order.totalAmountDue) + Number(this.charge);
    },
    showMobileMoneyChannel () {
      if (!this.businessInfo) { return false; }
      return this.businessInfo.paymentChannels.includes('mtn-gh') || this.businessInfo.paymentChannels.includes('vodafone-gh') || this.businessInfo.paymentChannels.includes('tigo-gh');
    },
    showBankCardChannel () {
      if (!this.businessInfo) { return false; }
      return this.businessInfo.paymentChannels.includes('cardnotpresent-visa') || this.businessInfo.paymentChannels.includes('cardnotpresent-mastercard');
    },
    showGhQrChannel () {
      if (!this.businessInfo) { return false; }
      return this.businessInfo.paymentChannels.includes('ghqr');
    },
    showHubtelChannel () {
      if (!this.businessInfo) { return false; }
      return this.businessInfo.paymentChannels.includes('hubtel-gh');
    },
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.paylinkDetails.paylinkUrl ?? '',
        customerName: this.paylinkDetails.customerName ?? '',
        title: this.paylinkDetails.paylinkTitle ?? '',
        description: this.paylinkDetails.paylinkDescription ?? '',
        phoneNumber: this.paylinkDetails.consumerSettlementNumber ?? this.paylinkDetails.customerMobileNumber,
        linkType: this.paylinkDetails.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.paylinkDetails.hasIdentifier ?? '',
        source: this.paylinkDetails.source ?? ''
      };
    }
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent',
      getStoreDetails: 'storeModule/getStoreDetails'
    }),
    checkIsTrustedChannel () {
      if (!this.isCustomerTrusted) {
        setMixPanelData(this.mixPanelData, 'checkout_wallet_selected', 'checkout', { walletType: 'momo', walletChannel: 'momo' });
        this.changeComponent('UntrustedMobileMoneyChannel');
      } else {
        setMixPanelData(this.mixPanelData, 'checkout_wallet_selected', 'checkout', { walletType: 'momo', walletChannel: 'momo' });
        this.changeComponent('MobileMoneyChannel');
      }
    },
    redirect () {
      setMixPanelData(this.mixPanelData, 'clicked_on_back_button', 'action');
      window.location.href = this.paylinkDetails.paylinkUrl;
    },
    handleChangeComponent (component, options) {
      setMixPanelData(this.mixPanelData, 'checkout_wallet_selected', 'checkout', options);
      this.changeComponent(component);
    }
  },
  mounted () {
    // reset charge
    this.$store.commit('chargeModule/setCharge', { fee: 0 });
  }
};
</script>

<style scoped>

</style>
