<template>
  <base-loader v-if="showLoader"></base-loader>
  <div class="tab-pane active" id="hubtelDetails" v-if="!showOtpVerification">
    <div>
      <div class="pb-4 mb-2">
       <base-header></base-header>
      </div>
      <div class="mb-4 mb-md-0">
        <h6 class="font-weight-bold mb-1">Pay with Hubtel</h6>
      </div>
      <div class="">
        <div class="login-phonenumber">
          <div class="form-group">
            <label class="text-body">Hubtel account number</label>
            <input ref="phoneNumberInput" type="tel" v-model="telephoneNumber" pattern="^[0-9]*$" class="form-control py-3 bg-white font-weight-bold" placeholder="eg. 024XXXXXXX" aria-label="Text input with dropdown button">
          </div>
          <div id="container"></div>
          <div class="mt-4 text-left d-flex flex-column-reverse" style="gap: 10px;">
            <a role="button" @click="changeComponent('BasePaymentChannels')" class="text-center mt-2 font-weight-bold text-uppercase">Back</a>
            <button type="button" class="btn btn-primary text-uppercase font-weight-bold py-3"  @click="sendOtpToCustomer" :disabled="disableContinueButton">Continue </button>
          </div>
        </div>
        <div class="p-2 bg-white pointer border-muted text-black switch-login d-none d-md-block my-4">
          <div class="w-100 scan-qr" style="">
            <div class="d-flex justify-content-between align-items-center">
              <p class="m-0 small">Pay by scanning a QR code instead</p>
              <img src="assets/images/qr-code.png" alt="qrcode" width="30">
            </div>
          </div>
          <div class="w-100 scan-phonenumber" style="display: none;">
            <div class="d-flex justify-content-between align-items-center">
              <p class="m-0 small">Pay with your phone number</p>
              <img src="assets/images/loginphone.png" alt="loginphone" width="25">
            </div>
          </div>
        </div>
        <div class="login-qr text-center" style="display: none;">
          <div>
            <img v-if="qrData" :src="qrData.qrCodeDataUrl" alt="qrcode" style="width: 150px;"/>
            <img v-else src="assets/images/qr_load.png" role="button" alt="qrIcon" @click="loadQr" style="width: 150px;" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <base-verify-otp v-else @verify="onOtpCodeVerified"></base-verify-otp>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoader from '@/components/ui/BaseLoader';
import BaseVerifyOtp from '@/components/ui/BaseVerifyOtp';
import io from 'socket.io-client';
import BaseHeader from '@/components/ui/BaseHeader';
import { onloadCallback, setMixPanelData } from '@/utils';

const $ = window.$;

export default {
  name: 'HubtelChannel',
  components: { BaseHeader, BaseLoader, BaseVerifyOtp },
  inject: ['logger'],
  data () {
    return {
      mobileNetworks: [
        {
          id: 'mtn-gh',
          name: 'MTN',
          icon: 'mtn-mobile-money.png',
          isDefault: false
        },
        {
          id: 'vodafone-gh',
          name: 'Vodafone',
          icon: 'vodafone-cash.png',
          isDefault: false
        },
        {
          id: 'tigo-gh',
          name: 'AirtelTigo',
          icon: 'ICO_Airtel_Cash_.png',
          isDefault: false
        },
        {
          id: 'glo',
          name: 'Glo',
          icon: 'Glo_Logo_New.png',
          isDefault: false
        }
      ],
      showLoader: false,
      socket: null,
      socketId: null,
      showOtpVerification: false,
      scanQrCode: false,
      telephoneNumber: '',
      recaptureToken: ''
    };
  },
  computed: {
    ...mapGetters({
      businessInfo: 'storeModule/businessInfo',
      qrData: 'customersModule/qrData',
      phoneNumber: 'customersModule/phoneNumber',
      customerData: 'customersModule/customerData',
      customerSession: 'customersModule/customerSession',
      selectedMobileNetwork: 'selectedMobileNetwork',
      orderId: 'orderId',
      order: 'storeModule/order',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    }),
    validatePhoneNumber () {
      return (this.telephoneNumber.length === 10 || this.telephoneNumber.length === 12 || this.telephoneNumber.length === 13);
    },
    disableContinueButton () {
      return !(this.validatePhoneNumber && this.recaptureToken);
    },
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.paylinkDetails.paylinkUrl ?? '',
        customerName: this.paylinkDetails.customerName ?? '',
        title: this.paylinkDetails.paylinkTitle ?? '',
        description: this.paylinkDetails.paylinkDescription ?? '',
        phoneNumber: this.paylinkDetails.consumerSettlementNumber ?? this.paylinkDetails.customerMobileNumber,
        linkType: this.paylinkDetails.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.paylinkDetails.hasIdentifier ?? '',
        source: this.paylinkDetails.source ?? ''
      };
    }
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent',
      sendOtp: 'customersModule/sendOtp',
      getQr: 'customersModule/getQr',
      getCustomer: 'customersModule/getCustomer',
      getCustomerWallets: 'walletsModule/getCustomerWallets'
    }),
    async sendOtpToCustomer () {
      try {
        this.showLoader = true;
        const key = `${this.orderId}_${this.telephoneNumber}`;
        if (this.customerSession.consumerKey === key) {
          this.$store.commit('customersModule/setPhoneNumber', this.telephoneNumber);
          await this.getCustomer({ phoneNumber: this.telephoneNumber, country: this.businessInfo.country });
          await this.getCustomerWallets({
            phoneNumber: this.telephoneNumber,
            country: this.businessInfo.country
          });
          this.changeComponent('CustomerWallets');
          return;
        }
        await this.sendOtp({
          phoneNumber: this.telephoneNumber,
          country: this.businessInfo.country,
          recaptureToken: this.recaptureToken
        });
        this.$store.commit('customersModule/setPhoneNumber', this.telephoneNumber);
        this.$store.commit('setCurrentPaymentChannel', 'Hubtel');
        this.showLoader = false;
        this.showOtpVerification = true;
      } catch (e) {
        this.showLoader = false;
        this.showOtpVerification = false;
      }
    },
    initializeQrCode () {
      // Socket IO
      this.socket = io('https://qr.hubtel.com');
      this.socket.on('connect', () => {
        this.socketId = this.socket.id;
        this.logger.debug('Connected Socket Id: ', this.socketId);
        this.loadQr();
      });

      this.socket.on('identity', (msg) => {
        this.logger.debug('Got ServerId Id', msg);
      });

      this.socket.on('login', (msg) => {
        this.logger.debug('Got Socket Data:login', msg);

        const socketData = msg.Data;

        this.$store.commit('customersModule/setCustomerAuthToken', socketData.token);
        this.$store.commit('customersModule/setPhoneNumber', socketData.customer.mobileNumber);
        this.$store.commit('setCurrentPaymentChannel', 'Hubtel');
        this.loadCustomerInfoFromQrCode();
      });

      this.socket.on('logout', (msg) => {
        this.logger.debug('Got Socket Data:login', msg);
      });
    },
    async loadQr () {
      try {
        const payload = {
          PlayerId: this.socketId,
          Platform: navigator.platform,
          Version: navigator.appVersion,
          Device: navigator.userAgent,
          DeviceId: navigator.appCodeName
        };
        await this.getQr(payload);
      } catch (e) {
        console.log(e);
      }
    },
    async loadCustomerInfoFromQrCode () {
      try {
        this.showLoader = true;
        await this.getCustomer({ phoneNumber: this.phoneNumber, country: this.businessInfo.country });
        await this.getCustomerWallets({
          phoneNumber: this.phoneNumber,
          country: this.businessInfo.country
        });
        setMixPanelData(this.mixPanelData, 'checkout_wallet_details_entered', 'checkout', { walletType: 'hubtel', walletChannel: 'hubtel' });
        this.changeComponent('CustomerWallets');
      } catch (e) {
        this.showLoader = false;
      }
    },
    async onOtpCodeVerified () {
      await this.getCustomer({ phoneNumber: this.phoneNumber, country: this.businessInfo.country });
      await this.getCustomerWallets({
        phoneNumber: this.phoneNumber,
        country: this.businessInfo.country
      });
      const key = `${this.orderId}_${this.phoneNumber}`;
      const payload = {
        consumerKey: key
      };
      this.$store.commit('customersModule/setCustomerSession', payload);
      setMixPanelData(this.mixPanelData, 'checkout_wallet_details_entered', 'checkout', { walletType: 'hubtel', walletChannel: 'hubtel' });
      this.changeComponent('CustomerWallets');
    },

    scanWithQr () {
      this.scanQrCode = !this.scanQrCode;
    },

    verifyCallback (response) {
      this.recaptureToken = response;
    },

    onRecaptchaExpired () {
      this.recaptureToken = '';
    }

  },
  created () {
    this.initializeQrCode();
  },
  mounted () {
    $(document).on('click', '.switch-login', function () {
      // activate slide toglle
      $('.login-phonenumber').slideToggle();
      $('.login-qr').slideToggle();
      $('.scan-qr').slideToggle();
      $('.scan-phonenumber').slideToggle();
    });
    onloadCallback(this.verifyCallback, this.onRecaptchaExpired);
  },

  unmounted () {
    $(document).off('click', '.switch-login');
  }
};
</script>
