import httpClient from '@/api/httpClient';

const BASE_URL = '/api/payment';

const checkStatus = (orderId, businessId) => httpClient.post(`${BASE_URL}/checkStatus/${orderId}/${businessId}`);
const directMobileMoney = (country, network, orderId, payload) => httpClient.post(`${BASE_URL}/DirectMobileMoney/${country}/${network}/${orderId}`, payload);
const directCard = (country, scheme, orderId, payload) => httpClient.post(`${BASE_URL}/DirectCard/${country}/${scheme}/${orderId}`, payload);
const hubtelWallet = (country, provider, orderId, payload) => httpClient.post(`${BASE_URL}/Hubtel/${country}/${provider}/${orderId}`, payload);
const mobileMoneyWallet = (country, network, orderId, payload) => httpClient.post(`${BASE_URL}/MobileMoney/${country}/${network}/${orderId}`, payload);
const cardWallet = (country, scheme, orderId, payload) => httpClient.post(`${BASE_URL}/Card/${country}/${scheme}/${orderId}`, payload);
const producePartialPaymentUpdateRequest = (payload) => httpClient.post(`${BASE_URL}/producePartialPaymentUpdateRequest`, payload);
const changePaymentMethod = (orderId) => httpClient.get(`${BASE_URL}/DifferentPaymentChannel/${orderId}`);
export {
  checkStatus,
  directMobileMoney,
  directCard,
  hubtelWallet,
  mobileMoneyWallet,
  cardWallet,
  producePartialPaymentUpdateRequest,
  changePaymentMethod
};
