<template>
  <div class="wrapper wrapper--no-sidebar">
    <div class="vh-100">
      <div class="row h-100 align-items-center justify-content-center no-gutters">
        <div class="col-12 col-sm-12 col-md-8 col-lg-5 col-xl-6">
          <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-12 col-xl-7">
              <div class="border bg-white">
                <slot></slot>
              </div>
              <div class="form-group">
                <slot name="footer"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseReceiptCard'
};
</script>

<style scoped>

</style>
