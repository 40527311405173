<template>
  <button type="button" class="mt-1 w-100 btn btn-primary text-uppercase font-weight-bold py-3" @click="checkStatus" :disabled="isDisabled"><span class="fa fa-spin fa-spinner" v-if="isDisabled"> </span>I have paid for this</button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { setMixPanelData } from '@/utils';
export default {
  name: 'BaseCheckPaymentStatus',
  data () {
    return {
      showPaymentPendingModal: false,
      isDisabled: false
    };
  },
  computed: {
    ...mapGetters({
      order: 'storeModule/order',
      businessInfo: 'storeModule/businessInfo',
      isCustomerTrusted: 'storeModule/isCustomerTrusted',
      currentPaymentChannel: 'currentPaymentChannel'
    }),
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.paylinkDetails.paylinkUrl ?? '',
        customerName: this.paylinkDetails.customerName ?? '',
        title: this.paylinkDetails.paylinkTitle ?? '',
        description: this.paylinkDetails.paylinkDescription ?? '',
        phoneNumber: this.paylinkDetails.consumerSettlementNumber ?? this.paylinkDetails.customerMobileNumber,
        linkType: this.paylinkDetails.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.paylinkDetails.hasIdentifier ?? '',
        source: this.paylinkDetails.source ?? ''
      };
    },
    channel () {
      let data;
      if (this.currentPaymentChannel.toLowerCase() === 'mobilemoney') {
        data = 'momo';
      } else {
        data = this.currentPaymentChannel.toLowerCase();
      }
      return data;
    }
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent',
      checkOrderStatus: 'paymentModule/checkOrderStatus'
    }),
    async checkStatus () {
      try {
        this.isDisabled = true;
        const response = await this.checkOrderStatus({
          orderId: this.order.id,
          businessId: this.businessInfo.id
        });
        this.isDisabled = false;
        const status = response.data.status;
        switch (status.toLowerCase()) {
          case 'pending':
            this.isDisabled = false;
            break;
          case 'failed':
            this.$store.commit('paymentModule/setErrorMessage', response.data.message);
            setMixPanelData(this.mixPanelData, 'order_payment_failed', 'checkout', { walletType: this.channel, walletChannel: this.channel, reason: response.data.message });
            this.changeComponent('BasePaymentFailed');
            break;
          case 'success':
            setMixPanelData(this.mixPanelData, 'order_paid', 'checkout', { walletType: this.channel, walletChannel: this.channel });
            this.changeComponent('BasePaymentSuccess');
            break;
        }
      } catch (e) {
        if (e.response) {
          setMixPanelData(this.mixPanelData, 'order_payment_failed', 'checkout', { walletType: this.channel, walletChannel: this.channel, reason: e.response.data.message });
        }
        this.isDisabled = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
