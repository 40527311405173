import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { formatAmount } from '@/utils';
import VueCardFormat from '@/directives/credit-card-validation';

const app = createApp(App);

app.config.globalProperties.$filters = {
  formatAmount
};

app.use(store);
app.use(router);
app.use(VueCardFormat);
app.mount('#app');
