import { createRouter, createWebHistory } from 'vue-router';
import Index from '../views/Index.vue';
import Receipt from '../views/Receipt/Index';

const routes = [
  {
    path: '/:orderId',
    name: 'Index',
    component: Index,
    props: true
  },
  {
    path: '/receipt',
    name: 'Receipt',
    component: Receipt
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
