<template>
  <teleport to="body">
    <div class="modal-backdrop fade show" @click="$emit('close')" v-if="showDialog"></div>
    <div class="modal-backdrop fade show" v-else></div>
    <div class="modal fade show d-block" tabindex="-1" role="dialog" aria-modal="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <button type="button" class="close" @click="closeModal"  aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: 'LayoutDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['close'],
  methods: {
    closeModal () {
      this.$emit('close', false);
    }
  }
};
</script>

<style scoped>

</style>
