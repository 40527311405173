<template>
  <div class="tab-pane text-center active" id="failedTab">
    <div>
      <div class="mt-5 pt-4">
        <img src="assets/images/failed.svg" alt="paymentFailed" class="w-25">
      </div>
      <div class="px-3 pt-1">
        <div>
          <div>
            <h6 class="font-weight-bold mb-1">Denied</h6>
            <p>This account of {{ paylinkDetails.customerName }}  ({{ paylinkDetails.customerMobileNumber }}) has been suspended or placed under temporary review.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseBlacklistedCard',
  computed: {
    ...mapGetters({
      order: 'storeModule/order'
    }),
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    }
  }
};
</script>
<style>

</style>
