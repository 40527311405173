<template>
  <base-loader v-if="showLoader"></base-loader>
  <div class="tab-pane active" id="enterOTP">
    <div>
      <div class="pb-4 mb-2">
        <base-header></base-header>
      </div>
      <div>
        <h6 class="font-weight-bold mb-1">Verify your phone number</h6>
        <p class="text-muted mb-1 small">Enter the 4 digit OTP sent to <strong>{{ phoneNumber }}</strong></p>
      </div>
      <div class="mt-3">
        <div>
          <div class="form-group">
            <div>
              <div class="d-flex align-items-center">
                <div>
                  <div class="bg-white text-black font-weight-bold" id="otpPrefix">{{ formattedOtpPrefix }} -</div>
                </div>
                <div class="ml-1">
                  <input id="otp1" @paste="handlePaste" class="tel_num bg-white form-control py-3" pattern="^[0-9]*$" type="tel" maxlength="1" placeholder="•" required="" autofocus="" v-model="otp1" ref="otp1" @keyup="focusOnPreviousElement">
                  <input id="otp2" @paste="handlePaste" class="tel_num bg-white form-control py-3" pattern="^[0-9]*$" type="tel" maxlength="1" placeholder="•" required="" v-model="otp2" ref="otp2" @keyup="focusOnPreviousElement">
                  <input id="otp3" @paste="handlePaste" class="tel_num bg-white form-control py-3" pattern="^[0-9]*$" type="tel" maxlength="1" placeholder="•" required="" v-model="otp3" ref="otp3" @keyup="focusOnPreviousElement">
                  <input id="otp4" @paste="handlePaste" class="tel_num bg-white form-control py-3" pattern="^[0-9]*$" type="tel" maxlength="1" placeholder="•" required="" v-model="otp4" ref="otp4" @keyup="focusOnPreviousElement">
                  <input max-length="4" type="tel" name="otp" id="otp" class="otpVerify form-control py-3 font-weight-bold" hidden="">
                </div>
              </div>
            </div>
          </div>
          <div class="my-4">
            <p class="small">Didn't receive the code? Dial<span class="font-weight-bold text-primary"> *713#, select option 5, Web Login</span> to view it</p>
          </div>
          <div class=" d-flex flex-column-reverse" style="gap: 10px;">
            <a role="button" @click="changeComponent('BasePaymentChannels')" class="text-center mt-2 font-weight-bold text-uppercase">Back</a>
            <a role="button" @click="resendOtpToCustomer"  class="text-primary btn btn-outline-primary text-uppercase font-weight-bold py-3" v-if="showResendAction">Resend Code</a>
            <button class="btn btn-primary text-uppercase font-weight-bold py-3" @click="verifyOtp" :disabled="disableButton">Verify</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoader from '@/components/ui/BaseLoader';
import BaseHeader from '@/components/ui/BaseHeader';

export default {
  name: 'BaseVerifyOtp',
  components: { BaseHeader, BaseLoader },
  emits: ['verify'],
  data () {
    return {
      showLoader: false,
      verifyErrorMessage: '',
      showReportModal: false,
      showResendAction: false,
      questions: ['I have not received my OTP', 'I am not able to verify my OTP'],
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: ''
    };
  },
  watch: {
    otp1 (newValue) {
      if (newValue && newValue.length === 1) {
        this.$nextTick(() => this.$refs.otp2.focus());
      }
    },
    otp2 (newValue) {
      if (newValue && newValue.length === 1) {
        this.$nextTick(() => this.$refs.otp3.focus());
      }
    },
    otp3 (newValue) {
      if (newValue && newValue.length === 1) {
        this.$nextTick(() => this.$refs.otp4.focus());
      }
    },
    otp4 (newValue) {
      if (newValue && newValue.length === 1) {
        this.verifyOtp();
      }
    }
  },
  computed: {
    ...mapGetters({
      otpData: 'customersModule/otpData',
      phoneNumber: 'customersModule/phoneNumber',
      businessInfo: 'storeModule/businessInfo',
      selectedMobileNetwork: 'selectedMobileNetwork',
      orderId: 'orderId',
      isCustomerTrusted: 'storeModule/isCustomerTrusted',
      currentPaymentChannel: 'currentPaymentChannel',
      cyberSourceTokenData: 'cyberSourceModule/cyberSourceTokenData'
    }),
    formattedOtpPrefix () {
      if (!this.otpData) {
        return '';
      }
      return this.otpData.otpPrefix;
    },
    disableButton () {
      return !([...this.otp1, ...this.otp2, ...this.otp3, ...this.otp4].length === 4);
    }
  },
  methods: {
    ...mapActions({
      confirmOtp: 'customersModule/confirmOtp',
      resendOtp: 'customersModule/resendOtp',
      changeComponent: 'changeComponent'
    }),
    async verifyOtp () {
      this.showLoader = true;
      try {
        await this.confirmOtp({
          phoneNumber: this.phoneNumber,
          country: this.businessInfo.country,
          requestId: this.otpData.requestId,
          otpCode: `${this.otpData.otpPrefix}-${this.otp1}${this.otp2}${this.otp3}${this.otp4}`
        });
        this.$emit('verify', true);
      } catch (error) {
        this.showLoader = false;
      }
    },
    async resendOtpToCustomer () {
      try {
        this.showLoader = true;
        await this.resendOtp({
          phoneNumber: this.phoneNumber,
          country: 'GH',
          requestId: this.otpData.requestId
        });
        this.showLoader = false;
      } catch (error) {
        this.showLoader = false;
      }
    },
    renderResendAction () {
      setTimeout(() => {
        this.showResendAction = true;
      }, 10000);
    },
    setShowModal (payload) {
      this.showReportModal = payload;
    },
    focusOnPreviousElement (e) {
      if (['Backspace', 'Delete'].includes(e.key)) {
        const prevSibling = e.target.previousElementSibling;
        if (prevSibling) {
          prevSibling.focus();
        }
      }
    },
    handlePaste (e) {
      const paste = (e.clipboardData || window.clipboardData).getData('text');
      paste.split('').slice(0, 4).forEach((code, index) => {
        this[`otp${index + 1}`] = code;
      });
    }
  },
  created () {
    this.renderResendAction();
    this.$nextTick(() => this.$refs.otp1.focus());
  }
};
</script>

<style scoped>
.resend-text:hover,
.resend-text:focus {
  color: #01c7b1 !important;
}
</style>
