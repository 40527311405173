<template>
  <div class="tab-pane text-center active" id="confirmUssd">
    <div>
      <base-header></base-header>
      <div class="mt-5">
        <img src="assets/images/hubtel-loader.gif" alt="gifloader" width="50">
      </div>
      <div class="p-3">
        <div>
          <div class="px-4">
            <p class="m-0 small">Dial *713*75# and follow the prompts to pay.</p>
          </div>
          <div class="pt-3 mt-3 text-left">
            <label class="small">Your reference code is</label>
            <div class="input-group">
              <input type="text" class="form-control py-3 font-weight-bold" :value="extraData.referenceCode" disabled="">
              <div class="input-group-append">
                <button class="btn form-control py-3 px-3" type="button" @click="copyReferenceCode"><span class="small text-primary font-weight-bold">{{isReferenceCodeCopied ? 'COPIED' : 'COPY'}}</span></button>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column-reverse mt-4" style="gap: 10px;">
            <a role="button" @click="handleChangeComponent('BasePaymentChannels')" class="text-center font-weight-bold text-uppercase mt-2">Cancel</a>
            <a role="button" @click="changeComponent('BaseUntrustedSuccess')" class="btn btn-primary text-uppercase font-weight-bold py-3">I have paid for this</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseHeader from '@/components/ui/BaseHeader';
import { setMixPanelData } from '@/utils';
export default {
  name: 'UntrustedMobileMoneyChannel',
  components: { BaseHeader },
  data () {
    return {
      isReferenceCodeCopied: false
    };
  },
  computed: {
    ...mapGetters({
      order: 'storeModule/order',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    }),
    extraData () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.extraData.paylinkUrl ?? '',
        customerName: this.extraData.customerName ?? '',
        title: this.extraData.paylinkTitle ?? '',
        description: this.extraData.paylinkDescription ?? '',
        phoneNumber: this.extraData.consumerSettlementNumber ?? this.extraData.customerMobileNumber,
        linkType: this.extraData.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.extraData.hasIdentifier ?? '',
        source: this.extraData.source ?? ''
      };
    }
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent'
    }),
    copyReferenceCode () {
      const copyText = this.extraData.referenceCode;
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        this.isReferenceCodeCopied = true;
      } catch (err) {
        console.log(err);
      }
      document.body.removeChild(textArea);
      this.isReferenceCodeCopied = true;
    },
    handleChangeComponent (component) {
      setMixPanelData(this.mixPanelData, 'order_cancelled', 'checkout', { walletType: 'momo', walletChannel: 'momo' });
      this.changeComponent(component);
    }
  }
};
</script>

<style scoped>

</style>
