import httpClient from '@/api/httpClient';

const BASE_URL = '/api/cyberSource';

const generateCyberSourceToken = () => httpClient.get(`${BASE_URL}/token`);
const validate3DSToken = (payload) => httpClient.post(`${BASE_URL}/validate3dstoken`, payload);
const cyberSource3dsCheck = (payload) => httpClient.post(`${BASE_URL}/3dsCheck`, payload);

export {
  generateCyberSourceToken,
  validate3DSToken,
  cyberSource3dsCheck
};
