export class Logger {
  static warning (message, context = null) {
    if (process.env.NODE_ENV === 'development') {
      console.warn(this.formatMessage('warning', context), message);
    }
  }

  static error (message, context = null) {
    if (process.env.NODE_ENV === 'development') {
      console.error(this.formatMessage('error', context), message);
    }
  }

  static debug (message, context = null) {
    if (process.env.NODE_ENV === 'development') {
      console.log(this.formatMessage('debug', context), message);
    }
  }

  static formatMessage (logType, context = null) {
    return context ? `[${logType}]: [${context}]: ` : `${logType}: `;
  }
}
