import httpClient from '@/api/httpClient';

const BASE_URL = '/api/wallets';

const getWallets = (phoneNumber, country) => httpClient.get(`${BASE_URL}/getWallets/${country}/${phoneNumber}`);
const addWallet = (country, type, payload) => httpClient.post(`${BASE_URL}/AddWallet/${country}/${type}`, payload);
const confirmDebit = (payload) => httpClient.post(`${BASE_URL}/ConfirmDebit/card`, payload);
const addCardToAccount = (referenceId) => httpClient.post(`${BASE_URL}/AddCardToAccount/${referenceId}`);

export {
  getWallets,
  addWallet,
  confirmDebit,
  addCardToAccount
};
