import { confirmOtp, generateQr, getCustomer, resendOtp, sendOtp } from '@/api/customers';

const customersModule = {
  namespaced: true,
  state () {
    return {
      otpData: null,
      phoneNumber: '',
      customerAuthToken: '',
      customerData: null,
      qrData: null,
      customerSession: {}
    };
  },
  mutations: {
    setOtpData (state, payload) {
      state.otpData = payload;
    },
    setPhoneNumber (state, payload) {
      state.phoneNumber = payload;
    },
    setCustomerAuthToken (state, payload) {
      state.customerAuthToken = payload;
    },
    setCustomerData (state, payload) {
      state.customerData = payload;
    },
    setQrData (state, payload) {
      state.qrData = payload;
    },
    setCustomerSession (state, payload) {
      state.customerSession = payload;
    }
  },
  actions: {
    async sendOtp (context, payload) {
      const response = await sendOtp(payload.phoneNumber, payload.country, payload.recaptureToken);
      context.commit('setOtpData', response.data.data);
    },
    async confirmOtp (context, payload) {
      const response = await confirmOtp(payload.phoneNumber, payload.country, payload.requestId, payload.otpCode);
      context.commit('setCustomerAuthToken', response.data.data.token);
    },
    async resendOtp (context, payload) {
      const response = await resendOtp(payload.phoneNumber, payload.country, payload.requestId);
      context.commit('setOtpData', response.data.data);
    },
    async getCustomer (context, payload) {
      const response = await getCustomer(payload.phoneNumber, payload.country);
      context.commit('setCustomerData', response.data.data);
    },
    async getQr (context, payload) {
      const response = await generateQr(payload);
      context.commit('setQrData', response.data.data);
    }
  },
  getters: {
    otpData (state) {
      return state.otpData;
    },
    phoneNumber (state) {
      return state.phoneNumber;
    },
    customerAuthToken (state) {
      return state.customerAuthToken;
    },
    customerData (state) {
      return state.customerData;
    },
    qrData (state) {
      return state.qrData;
    },
    customerSession (state) {
      return state.customerSession;
    }
  }
};

export default customersModule;
