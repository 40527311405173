import { createStore } from 'vuex';
import storeModule from '@/store/modules/store';
import customersModule from '@/store/modules/customers';
import paymentModule from '@/store/modules/payment';
import cyberSourceModule from '@/store/modules/cyberSource';
import ghqrModule from '@/store/modules/ghqr';
import chargeModule from '@/store/modules/charges';
import walletsModule from '@/store/modules/wallets';
import supportModule from '@/store/modules/support';
import createPersistedState from 'vuex-persistedstate';
const store = createStore({
  state () {
    return {
      componentName: 'PaymentChannels',
      orderId: null,
      selectedMobileNetwork: null,
      currentPaymentChannel: null,
      modalConfig: {
        visible: false,
        message: ''
      }
    };
  },
  mutations: {
    setComponentName (state, payload) {
      state.componentName = payload;
    },
    setOrderId (state, payload) {
      state.orderId = payload;
    },
    setSelectedMobileNetwork (state, payload) {
      state.selectedMobileNetwork = payload;
    },
    setModalConfig (state, payload) {
      state.modalConfig = payload;
    },
    setCurrentPaymentChannel (state, payload) {
      state.currentPaymentChannel = payload;
    }
  },
  actions: {
    changeComponent (context, payload) {
      context.commit('setComponentName', payload);
    },
    displayModal (context, payload) {
      context.commit('setModalConfig', payload);
    }
  },
  getters: {
    currentComponent (state) {
      return state.componentName;
    },
    orderId (state) {
      return state.orderId;
    },
    selectedMobileNetwork (state) {
      return state.selectedMobileNetwork;
    },
    modalConfig (state) {
      return state.modalConfig;
    },
    currentPaymentChannel (state) {
      return state.currentPaymentChannel;
    }
  },
  modules: {
    storeModule,
    customersModule,
    paymentModule,
    ghqrModule,
    chargeModule,
    cyberSourceModule,
    walletsModule,
    supportModule
  },
  plugins: [createPersistedState({
    paths: ['customersModule']
  })]
});

export default store;
