import httpClient from '@/api/httpClient';

const BASE_URL = '/api/customers';

const sendOtp = (phoneNumber, country, recaptureToken) => httpClient.get(`${BASE_URL}/sendOtp/${phoneNumber}/${country}?recaptureToken=${recaptureToken}`);
const confirmOtp = (phoneNumber, country, requestId, otpCode) => httpClient.get(`${BASE_URL}/confirmOtp/${phoneNumber}/${country}/${requestId}/${otpCode}`);
const resendOtp = (phoneNumber, country, requestId) => httpClient.get(`${BASE_URL}/resendOtp/${phoneNumber}/${country}/${requestId}`);
const getCustomer = (phoneNumber, country) => httpClient.get(`${BASE_URL}/${phoneNumber}/${country}`);
const generateQr = (payload) => httpClient.post(`${BASE_URL}/GenerateQr`, payload);

export {
  sendOtp,
  confirmOtp,
  resendOtp,
  getCustomer,
  generateQr
};
