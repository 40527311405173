<template>
  <div class="px-4 pt-4">
    <h6 class="font-weight-bold">How we keep your card safe</h6>
    <div class="mt-3">
      <ul class="p-0 m-0 text-black">
        <ol class="p-0 mb-3">
          1. We process payments directly with card gateways and do not use any middleware or providers.
        </ol>
        <ol class="p-0 mb-3">
          2. We NEVER store your full card details on our systems or databases.
        </ol>
        <ol class="p-0 mb-3">
          3. We are a regulated payment service provider so our activities are regularly audited.
        </ol>
        <ol class="p-0 mb-3">
          4. We process refunds almost immediately. You will never have to wait for days to get your money back if
          something goes wrong.
        </ol>
        <ol class="p-0 mb-3">
          5. We are PCI-DSS and ISO-27001 compliant; meaning that all our processes &amp; employees adhere to strict
          industry standards.
        </ol>
      </ul>
    </div>
    <div>
      <div class="d-flex">
        <div class="d-flex mb-3 align-items-center">
          <img src="assets/images/flat.svg" alt="coupon" class="" height="25">
          <p class="mb-0 ml-2 small text-muted">Free Returns Guarantee</p>
        </div>
        <div class="d-flex mb-3 align-items-center">
          <img src="assets/images/cash.svg" alt="cash" class="" height="20">
          <p class="mb-0 text-muted small ml-2">Instant Money-Back Guarantee</p>
        </div>
        <div class="d-flex pointer" data-toggle="modal" data-target="#pci">
          <img src="assets/images/pecb.png" alt="pceb" class="" width=""
               height="25">
          <img src="assets/images/pci.png" alt="pci" class="ml-2" width=""
               height="25">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PCIContent'
};
</script>

<style scoped>

</style>
