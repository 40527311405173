<template>
  <div class="mt-4">
    <div>
      <div class="form-group">
        <div class="mb-3">
          <label class="mb-1 text-body">Select Provider</label>
          <select class="form-control py-3 bg-white h-100" v-model="selectedNetwork" @change="getNetworkId">
            <option value="">Select a network</option>
            <option  v-for="network in mobileNetworks" :key="network" :value="network.id">{{network.name}}</option>
          </select>
        </div>
      </div>
      <div class="py-4">
        <label class="mb-1">Mobile money number</label>
        <input type="tel" v-model="phoneNumber" pattern="^[0-9]*$" class="form-control py-3 bg-white font-weight-bold" placeholder="eg. 024XXXXXXX" aria-label="Text input with dropdown button">
      </div>
      <div id="container"></div>
      <div class="py-3 text-left d-flex flex-column-reverse" style="gap: 10px;">
        <a role="button" @click="changeComponent('BasePaymentChannels')" class="text-center font-weight-bold text-uppercase">Back</a>
        <button  type="button" class="btn btn-primary text-uppercase font-weight-bold py-3" :disabled="disableContinueButton" @click="verifyPhoneNumber">Continue </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { onloadCallback } from '@/utils';

export default {
  name: 'BaseMobileNetworkInput',
  emits: ['verify', 'getNetwork'],
  props: {
    paymentChannels: {
      type: Array,
      default: () => []
    },
    mobileNetworks: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data () {
    return {
      phoneNumber: null,
      selectedNetwork: '',
      showLoader: false,
      recaptureToken: ''
    };
  },
  computed: {
    displayNetworkDropdown () {
      return this.phoneNumber && (this.phoneNumber.length === 10 || this.phoneNumber.length === 12 || this.phoneNumber.length === 13);
    },
    disableContinueButton () {
      return !(this.selectedNetwork && this.displayNetworkDropdown && this.recaptureToken);
    },
    ...mapGetters({
      order: 'storeModule/order',
      businessInfo: 'storeModule/businessInfo'
    })
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent'
    }),
    verifyPhoneNumber () {
      this.$emit('verify', {
        phoneNumber: this.phoneNumber,
        network: this.selectedNetwork,
        recaptureToken: this.recaptureToken
      });
    },
    getNetworkId () {
      this.$emit('getNetwork', {
        network: this.selectedNetwork
      });
    },

    verifyCallback (response) {
      this.recaptureToken = response;
    },

    onRecaptchaExpired () {
      this.recaptureToken = '';
    }
  },
  mounted () {
    onloadCallback(this.verifyCallback, this.onRecaptchaExpired);
  }
};
</script>

<style scoped>

</style>
