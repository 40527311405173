import { cyberSource3dsCheck, generateCyberSourceToken, validate3DSToken } from '@/api/cyberSource';
import { checkFraudLabStatus, sendFraudLabRequest, send3dsEnrollmentRequest } from '@/api/fraudlab';
import { send3DEnrollRequest, send3DSetupRequest } from '@/api/precheckout';

const cyberSourceModule = {
  namespaced: true,
  state () {
    return {
      cyberSourceTokenData: null
    };
  },
  mutations: {
    setCyberSourceTokenData (state, payload) {
      state.cyberSourceTokenData = payload;
    }
  },
  actions: {
    async getCyberSourceToken (context) {
      const response = await generateCyberSourceToken();
      context.commit('setCyberSourceTokenData', response.data.data);
    },
    async validate3DSToken (context, payload) {
      return await validate3DSToken(payload);
    },
    async cyberSource3dsCheck (context, payload) {
      return await cyberSource3dsCheck(payload);
    },
    async checkFraudLabStatus (context, payload) {
      return await checkFraudLabStatus(payload);
    },
    async sendFraudLabRequest (context, payload) {
      return await sendFraudLabRequest(payload);
    },
    async send3DEnrollRequest (context, payload) {
      return await send3DEnrollRequest(payload);
    },
    async send3DSetupRequest (context, payload) {
      return await send3DSetupRequest(payload);
    },
    async send3dsEnrollmentRequest (context, payload) {
      return await send3dsEnrollmentRequest(payload);
    }
  },
  getters: {
    cyberSourceTokenData (state) {
      return state.cyberSourceTokenData;
    }
  }
};
export default cyberSourceModule;
