import { getWallets, addWallet, confirmDebit, addCardToAccount } from '@/api/wallets';

const walletsModule = {
  namespaced: true,
  state () {
    return {
      customerWalletsData: {},
      transactionId: null
    };
  },
  mutations: {
    setCustomerWalletsData (state, payload) {
      state.customerWalletsData = payload;
    },
    setTransactionId (state, payload) {
      state.transactionId = payload;
    }
  },
  actions: {
    async getCustomerWallets (context, payload) {
      const response = await getWallets(payload.phoneNumber, payload.country);
      context.commit('setCustomerWalletsData', response.data.data);
    },
    async addCustomerWallet (context, payload) {
      return await addWallet(payload.country, payload.type, payload.data);
    },
    async confirmDebitCard (context, payload) {
      return await confirmDebit(payload);
    },
    async addCardToAccount (context, payload) {
      return await addCardToAccount(payload);
    }
  },
  getters: {
    customerWalletsData (state) {
      return state.customerWalletsData;
    },
    transactionId (state) {
      return state.transactionId;
    }
  }
};
export default walletsModule;
