<template>
  <div class="col-12 col-md-12 col-lg-6 col-xl-6 pl-md-1">
    <div class="bg-white p-4 p-lg-5 h-100">
      <div style="height:430px">
        <div>
          <div class="tab-content" id="nav-tabContent">
            <p class="animated-background placeholder-title rounded" style="height: 340px;"></p>
          </div>
        </div>
      </div>
      <div class=" pt-5 text-center">
        <div class="">
          <p class="animated-background placeholder-title rounded" style="height: 36px;"></p>
        </div>
        <div>
          <p class="animated-background placeholder-title rounded" style="height: 16px;"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightSidebarLoader'
};
</script>

<style scoped>
.animated-background {
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #e1e4ea 10%, #dde0e6 18%, #e1e4ea 33%);
  background-size: 800px 104px;
  position: relative;
}
</style>
