<template>
  <div class="tab-pane text-center active" id="failedTab">
    <div>
      <base-header></base-header>
      <div class="mt-5 pt-4">
        <img src="assets/images/failed.svg" alt="paymentFailed" class="w-25">
      </div>
      <div class="px-3 pt-1">
        <div>
          <div>
            <h6 class="font-weight-bold mb-1">Payment failed</h6>
            <p>{{errorMessage ? errorMessage : 'Make sure all information is correct and try again'}}</p>
          </div>
          <div class="d-flex flex-column-reverse mt-4" style="gap: 10px;">
            <a role="button" @click="changeComponent('BasePaymentChannels')" class="text-center mt-2 font-weight-bold text-uppercase">Close</a>
            <button type="button" class="btn btn-primary text-uppercase font-weight-bold py-3 px-5" @click="changePaymentMethod" :disabled="isDisabled"><span class="fa fa-spin fa-spinner" v-if="isDisabled"></span> Try again</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseHeader from '@/components/ui/BaseHeader';
export default {
  name: 'BasePaymentFailed',
  components: { BaseHeader },
  data () {
    return {
      isDisabled: false
    };
  },
  computed: {
    ...mapGetters({
      errorMessage: 'paymentModule/getErrorMessage',
      order: 'storeModule/order'
    })
  },
  methods: {
    ...mapActions({
      changePaymentWallet: 'paymentModule/changePaymentWallet',
      getStoreDetails: 'storeModule/getStoreDetails',
      changeComponent: 'changeComponent'
    }),
    async changePaymentMethod () {
      try {
        this.isDisabled = true;
        const { data: res } = await this.changePaymentWallet(this.order.id);
        const orderId = res.data.orderId;
        this.$store.commit('setOrderId', orderId);
        await this.getStoreDetails(orderId);
        await this.$router.push({ name: 'Index', params: { orderId } });
        this.changeComponent('BasePaymentChannels');
        this.$store.commit('paymentModule/setErrorMessage', '');
        this.isDisabled = false;
      } catch (e) {
        this.isDisabled = false;
      }
    }
  }
};
</script>

<style scoped>

</style>
