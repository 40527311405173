<template>
  <layout-dialog v-if="showDialog" @close="closeModal">
  <PCIContent />
  </layout-dialog>
  <right-sidebar-loader v-if="showLoader"></right-sidebar-loader>
  <template v-else>
    <div class="col-12 col-md-12 col-lg-6 col-xl-6 pl-md-1">
      <div class="h-100">
        <div class="bg-white pt-4 px-4 px-lg-5 h-100">
          <div class="paylink-card d-flex flex-column justify-content-around">
            <div>
              <div class="tab-content" id="nav-tabContent">
                <div>
                  <component :is="currentComponent"></component>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center" v-if="order">
            <div class="d-none d-lg-block pt-5">
              <p class="small mb-2">
                You can pay with
              </p>
              <div class="mb-3">
                <img src="/assets/images/telcosGroup.png" alt="telcos" style="width:180px">
              </div>
              <p class="small mb-2 mb-lg-0">
                <i class="fas fa-lock ml-2 text-primary"></i>
                Secured with <a href="https://hubtel.com" class="text-decoration-none" target="”_blank”">Hubtel</a>
              </p>
            </div>
            <div class="d-block d-lg-none pt-2">
              <p class="m-0 p-3 rounded small pointer"  @click="openDialog">
                <i class="fas fa-shield-alt ml-2"></i>
                Your money is safe. <span class="text-primary">SEE HOW</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center pt-3 d-block d-lg-none">
      <p class="small mb-2">
        You can pay with
      </p>
      <div class="mb-3">
        <img src="/assets/images/telcosGroup.png" alt="telcos" style="width:180px">
      </div>
      <p class="small mb-2 mb-lg-0">
        <i class="fas fa-lock ml-2 text-primary"></i>
        Secured with <a href="https://hubtel.com" class="text-decoration-none" target="”_blank”">Hubtel</a>
      </p>
    </div>
  </template>
</template>

<script>
import BasePaymentChannels from '@/components/ui/BasePaymentChannels';
import MobileMoneyChannel from '@/components/channels/MobileMoneyChannel';
import UntrustedMobileMoneyChannel from '@/components/channels/UntrustedMobileMoneyChannel';
import GhQrChannel from '@/components/channels/GhQrChannel';
import HubtelChannel from '@/components/channels/HubtelChannel';
import BankCardChannel from '@/components/channels/BankCardChannel';
import MobileMoneyBillPrompt from '@/components/channels/mobile-money/MobileMoneyBillPrompt';
import BasePaymentPending from '@/components/ui/BasePaymentPending';
import BasePaymentFailed from '@/components/ui/BasePaymentFailed';
import BasePaymentSuccess from '@/components/ui/BasePaymentSuccess';
import BaseUntrustedSuccess from '@/components/ui/BaseUntrustedSuccess';
import BaseBlankCard from '@/components/ui/BaseBlankCard';
import CustomerWallets from '@/components/wallets/CustomerWallets';
import RightSidebarLoader from '@/components/layout/RightSidebarLoader';
import BaseHeader from '@/components/ui/BaseHeader';
import LayoutDialog from '@/components/layout/LayoutDialog';
import PCIContent from '@/components/layout/PCIContent';
import { mapGetters } from 'vuex';

export default {
  name: 'RightSidebar',
  components: {
    PCIContent,
    BaseHeader,
    RightSidebarLoader,
    BasePaymentChannels,
    MobileMoneyChannel,
    GhQrChannel,
    HubtelChannel,
    BankCardChannel,
    MobileMoneyBillPrompt,
    BasePaymentPending,
    BasePaymentFailed,
    BasePaymentSuccess,
    BaseBlankCard,
    CustomerWallets,
    UntrustedMobileMoneyChannel,
    LayoutDialog,
    BaseUntrustedSuccess
  },
  props: {
    showLoader: {
      required: true,
      type: Boolean
    },
    currentComponent: {
      required: true,
      type: String
    }
  },
  computed: {
    ...mapGetters({
      order: 'storeModule/order'
    })
  },
  data () {
    return {
      showDialog: false
    };
  },
  methods: {
    closeModal (payload) {
      this.showDialog = payload;
    },
    openDialog () {
      this.showDialog = true;
    }
  }
};
</script>

<style scoped>

</style>
