import { generateCode } from '@/api/ghqr';

const ghqrModule = {
  namespaced: true,
  state () {
    return {
      ghqr: {}
    };
  },
  mutations: {
    setGhqrCode (state, payload) {
      state.ghqr = payload;
    }
  },
  actions: {
    async generateGhqr (context, payload) {
      const id = payload.orderId;
      const response = await generateCode(id, payload);
      context.commit('setGhqrCode', response.data);
    }
  },
  getters: {
    getGhqr (state) {
      return state.ghqr;
    }
  }
};

export default ghqrModule;
