<template>
  <div class="cards full-container">
    <div class="slider slider-nav mb-0 position-relative bg-white">
      <div class="payment-cards mobile-money position-relative" style="width: 100%; display: inline-block;" v-for="wallet in customerWalletsData?.wallets" :key="wallet.id">
        <img :src="getCardImage(wallet.type, wallet.provider)" class="img-fluid bg h-100 w-100" alt="card" />
        <img :src="getCardImage(wallet.type, wallet.provider)" class="img-fluid cardtype" alt="bankLogo" v-if="wallet.type && wallet.type.toLowerCase() === 'card'" />
        <img src="assets/images/visa-white.png" alt="visa" v-if="wallet.type && wallet.type.toLowerCase() === 'card' && wallet.providerType.toLowerCase() === 'visa'" class="img-fluid cardtype" />
        <img src="assets/images/visa-white.png" alt="mastercard" v-if="wallet.type && wallet.type.toLowerCase() === 'card' && wallet.providerType.toLowerCase() === 'mastercard'" class="img-fluid cardtype" />
        <div class="position-absolute text-dark info" style="bottom:25%; left:5%;" v-if="wallet.type && wallet.type.toLowerCase() === 'hubtel'">
          <div class="mb-1 text-white"><span>{{wallet.accountName}}</span></div>
          <div><h6 class="font-weight-bold text-white">{{wallet.accountNo}}</h6></div>
        </div>
        <div class="position-absolute text-dark info" style="bottom:25%; left:5%;" v-else-if="wallet.type && wallet.type.toLowerCase() === 'momo'">
          <div class="text-white"><span>{{wallet.accountName}}</span></div>
          <div><h6 class="font-weight-bold text-white">{{wallet.accountNo}}</h6></div>
        </div>
        <div class="position-absolute info" style="bottom:25%; left:5%;" v-else-if="wallet.type && wallet.type.toLowerCase() === 'card'">
          <div class="mb-1"> <span class="text-white">{{wallet.accountName}}</span> </div>
          <div class="">
            <h5 class="">{{formatCardNumber(wallet.accountNo)}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'BaseSlickCard',
  computed: {
    ...mapGetters({
      customerWalletsData: 'walletsModule/customerWalletsData',
      order: 'storeModule/order'
    })
  },
  methods: {
    ...mapActions({
      calculateCharges: 'chargeModule/calculateCharges',
      changeComponent: 'changeComponent'
    }),
    getCardImage (type, provider) {
      switch (type.toLowerCase()) {
        case 'hubtel':
          return 'assets/images/hubtel-wallet-card.png';
        case 'momo':
          if (provider.toLowerCase() === 'vodafone') {
            return 'assets/images/vodafone-cash-card.png';
          }
          if (provider.toLowerCase() === 'mtn') {
            return 'assets/images/mtn-mobile-money-card.png';
          }
          if (provider.toLowerCase() === 'tigo') {
            return 'assets/images/airtel-tigo-money-card.png';
          }
          break;
        case 'card':
          if (provider.toLowerCase().includes('ecobank')) {
            return 'assets/images/bankcards/Ecobank.png';
          }
          if (provider.toLowerCase().includes('barclays')) {
            return 'assets/images/bankcards/Barclays.png';
          }
          if (provider.toLowerCase().includes('calbank')) {
            return 'assets/images/bankcards/Calbank.png';
          }
          if (provider.toLowerCase().includes('gcb')) {
            return 'assets/images/bankcards/GCB.png';
          }
          if (provider.toLowerCase().includes('guaranty trust bank')) {
            return 'assets/images/bankcards/GTBank.png';
          }
          if (provider.toLowerCase().includes('stanbic')) {
            return 'assets/images/bankcards/Stanbic Bank.png';
          }
          if (provider.toLowerCase().includes('standard')) {
            return 'assets/images/bankcards/StandardChartered Bank.png';
          }
          if (provider.toLowerCase().includes('uba')) {
            return 'assets/images/bankcards/UBA.png';
          }
          if (provider.toLowerCase().includes('first national bank')) {
            return 'assets/images/bankcards/fnb.png';
          }
          if (provider.toLowerCase().includes('zenith')) {
            return 'assets/images/bankcards/Zenith.png';
          } else return 'assets/images/bankcards/Generic.png';
      }
    },
    formatCardNumber (cardNumber) {
      if (!cardNumber) { return ''; }
      cardNumber = cardNumber.toLowerCase();
      cardNumber = cardNumber.replace('xxxxxx', '******');
      return cardNumber;
    },
    onSlickCardSelected (wallet) {
      this.$emit('card-select', wallet);
    },
    async getWalletCharge (provider, type, scheme = '') {
      if (provider && type) {
        const payload = {
          provider,
          type,
          orderId: this.order.id
        };
        if (type.toLowerCase() === 'card') {
          payload.provider = 'cardnotpresent-' + scheme.toLowerCase();
        }
        await this.calculateCharges(payload);
      }
    },
    addWallet () {
      this.changeComponent('AddWallet');
    }
  },
  mounted () {
    const $ = window.$;
    const $sliderNavSelector = $('.slider-nav');
    $sliderNavSelector.slick({
      speed: 700,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      dots: false,
      focusOnSelect: true,
      variableWidth: true,
      responsive: [{
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false
        }
      }
      ]
    });
    const hubtelWalletIndex = this.customerWalletsData.wallets.findIndex(w => w.provider.toLowerCase() === 'hubtel');
    if (hubtelWalletIndex >= 0) {
      $sliderNavSelector.slick('slickGoTo', hubtelWalletIndex);
    }

    $sliderNavSelector.on('afterChange', async (event, slick, currentSlide, nextSlide) => {
      const activeWallet = this.customerWalletsData.wallets[currentSlide];
      this.$emit('card-select', activeWallet);
      const provider = `${activeWallet?.provider.toLowerCase()}-${activeWallet?.countryCode.toLowerCase()}`;
      await this.getWalletCharge(provider, activeWallet?.type, activeWallet?.providerType);
    });
  }

};
</script>

<style scoped>

</style>
