import { getStore } from '@/api/store';

const storeModule = {
  namespaced: true,
  state () {
    return {
      order: null,
      businessInfo: null,
      isCustomerTrusted: false,
      isCustomerBlacklisted: false
    };
  },
  mutations: {
    setStoreDetails (state, payload) {
      state.order = payload.order;
      state.businessInfo = payload.businessInfo;
      state.isCustomerTrusted = payload.isCustomerTrusted;
      state.isCustomerBlacklisted = payload.isCustomerBlacklisted;
    }
  },
  actions: {
    async getStoreDetails (context, payload) {
      const response = await getStore(payload);
      context.commit('setStoreDetails', response.data.data);
    }
  },
  getters: {
    order (state) {
      return state.order;
    },
    businessInfo (state) {
      return state.businessInfo;
    },
    isCustomerTrusted (state) {
      return state.isCustomerTrusted;
    },
    isCustomerBlacklisted (state) {
      return state.isCustomerBlacklisted;
    }
  }
};

export default storeModule;
