<template>
<div style="height: 500px"></div>
</template>

<script>
export default {
  name: 'BlankCard'
};
</script>

<style scoped>

</style>
