const numeral = require('numeral');
const mixpanel = require('mixpanel-browser');

const formatAmount = (amount) => {
  if (amount === 0 || amount) {
    return numeral(amount).format('0,0.00');
  }
  return amount;
};

const shortenText = (value = '', maxLength = 50) => {
  if (!value) { return value; }
  if (value.length <= 0) { return value; }
  return value.length > 50 ? value.substr(0, 50) + '....' : value;
};

const getData = (payload, options) => {
  const data = {
    page_name: 'checkout_page',
    link: payload.paylinkUrl,
    receiver_name: payload.customerName,
    description: payload.title,
    receiver_account: payload.phoneNumber,
    is_trusted: payload.isCustomerTrusted,
    is_user_verified: true,
    amount_type: payload.linkType,
    amount: payload.totalAmountDue,
    purpose: payload.description.toLowerCase(),
    has_unique_identifier: payload.hasIdentifier,
    user_type: 'consumer',
    link_source: payload.source
  };
  if (options.walletType) {
    data.wallet_type = options.walletType;
  }
  if (options.walletChannel) {
    data.wallet_channel = options.walletChannel;
  }
  if (options.reason) {
    data.reason = options.reason;
  }
  return data;
};

mixpanel.init('44780cd227becfbe910d3f23cf521d9b');

const setMixPanelData = (payload, typeOfAction, action, options = {}) => {
  return mixpanel.track(action, {
    data: getData(payload, options),
    type: typeOfAction,
    app_name: 'take_payments_web_app'
  });
};

const onloadCallback = (verifyCallback, onRecaptchaExpired) => {
  window.grecaptcha.ready(function () {
    window.grecaptcha.render('container', {
      sitekey: '6Lc2iQgkAAAAALD7lvJv9Zm1iRyx7twCDQSL129u',
      callback: verifyCallback,
      'expired-callback': onRecaptchaExpired
    });
  });
};

export {
  formatAmount,
  shortenText,
  setMixPanelData,
  onloadCallback
};
