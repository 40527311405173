<template>
  <base-loader v-if="showLoader"></base-loader>
  <div class="tab-pane active" id="momoDetails" v-if="!showOtpVerification">
    <div>
      <div class="pb-4 mb-2">
        <base-header></base-header>
      </div>
      <div class="">
        <h6 class="font-weight-bold mb-1">Pay with mobile money</h6>
      </div>
      <base-mobile-network-input
          :mobile-networks="mobileNetworks"
          @verify="sendOtpToCustomer($event)"
          @getNetwork="getNetworkFees"
      >
      </base-mobile-network-input>
    </div>
  </div>
  <base-verify-otp v-else @verify="onOtpCodeVerified"></base-verify-otp>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoader from '@/components/ui/BaseLoader';
import BaseMobileNetworkInput from '@/components/ui/BaseMobileNetworkInput';
import BaseVerifyOtp from '@/components/ui/BaseVerifyOtp';
import BaseHeader from '@/components/ui/BaseHeader';
import { setMixPanelData } from '@/utils';

export default {
  name: 'MobileMoneyChannel',
  components: {
    BaseHeader,
    BaseLoader,
    BaseMobileNetworkInput,
    BaseVerifyOtp
  },
  data () {
    return {
      showLoader: false,
      mobileNetworks: [
        {
          id: 'mtn-gh',
          name: 'MTN',
          icon: 'mtn-mobile-money.png',
          isDefault: false
        },
        {
          id: 'vodafone-gh',
          name: 'Vodafone',
          icon: 'vodafone-cash.png',
          isDefault: false
        },
        {
          id: 'tigo-gh',
          name: 'At',
          icon: 'ICO_Airtel_Cash_.png',
          isDefault: false
        }
      ],
      showOtpVerification: false,
      isReferenceCodeCopied: false
    };
  },
  computed: {
    ...mapGetters({
      order: 'storeModule/order',
      businessInfo: 'storeModule/businessInfo',
      selectedMobileNetwork: 'selectedMobileNetwork',
      phoneNumber: 'customersModule/phoneNumber',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    }),
    extraData () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.extraData.paylinkUrl ?? '',
        customerName: this.extraData.customerName ?? '',
        title: this.extraData.paylinkTitle ?? '',
        description: this.extraData.paylinkDescription ?? '',
        phoneNumber: this.extraData.consumerSettlementNumber ?? this.extraData.customerMobileNumber,
        linkType: this.extraData.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.extraData.hasIdentifier ?? '',
        source: this.extraData.source ?? ''
      };
    }
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent',
      createDirectMobileMoneyRequest: 'paymentModule/createDirectMobileMoneyRequest',
      sendOtp: 'customersModule/sendOtp',
      calculateCharges: 'chargeModule/calculateCharges'
    }),
    async sendOtpToCustomer (data) {
      try {
        this.showLoader = true;
        await this.sendOtp({
          phoneNumber: data.phoneNumber,
          country: this.businessInfo.country,
          recaptureToken: data.recaptureToken
        });
        this.$store.commit('customersModule/setPhoneNumber', data.phoneNumber);
        this.$store.commit('setSelectedMobileNetwork', data.network);
        this.$store.commit('setCurrentPaymentChannel', 'MobileMoney');
        this.showLoader = false;
        this.showOtpVerification = true;
      } catch (e) {
        this.showLoader = false;
        this.showOtpVerification = false;
      }
    },
    async onOtpCodeVerified () {
      try {
        await this.createDirectMobileMoneyRequest({
          country: this.businessInfo.country,
          network: this.selectedMobileNetwork,
          orderId: this.order.id,
          data: {
            phoneNumber: this.phoneNumber,
            customerReward: 0
          }
        });
        setMixPanelData(this.mixPanelData, 'checkout_wallet_details_entered', 'checkout', { walletType: this.selectedMobileNetwork, walletChannel: this.selectedMobileNetwork });
        this.changeComponent('MobileMoneyBillPrompt');
      } catch (e) {
        this.showLoader = false;
        this.showOtpVerification = false;
      }
    },

    async getNetworkFees ({ network }) {
      if (network) {
        const payload = {
          provider: network.toLowerCase(),
          type: 'MOMO',
          orderId: this.order.id
        };
        await this.calculateCharges(payload);
      } else {
        // reset charge
        this.$store.commit('chargeModule/setCharge', { fee: 0 });
      }
    }

  }
};
</script>

<style scoped>

</style>
