import {
  checkStatus,
  directCard,
  directMobileMoney,
  hubtelWallet,
  mobileMoneyWallet,
  cardWallet,
  producePartialPaymentUpdateRequest,
  changePaymentMethod
} from '@/api/payment';

const paymentModule = {
  namespaced: true,
  state () {
    return {
      statusCheckData: {},
      errorMessage: ''
    };
  },
  mutations: {
    setStatusCheckData (state, payload) {
      state.statusCheckData = payload;
    },
    setErrorMessage (state, payload) {
      state.errorMessage = payload;
    }
  },
  actions: {
    async checkOrderStatus (context, payload) {
      const response = await checkStatus(payload.orderId, payload.businessId);
      context.commit('setStatusCheckData', response.data);
      return response;
    },
    async createDirectMobileMoneyRequest (context, payload) {
      return await directMobileMoney(payload.country, payload.network, payload.orderId, payload.data);
    },
    async chargeCardDirect (context, payload) {
      return await directCard(payload.country, payload.scheme, payload.orderId, payload.data);
    },
    async chargeHubtelWallet (context, payload) {
      return await hubtelWallet(payload.country, payload.provider, payload.orderId, payload.data);
    },
    async chargeMobileMoneyWallet (context, payload) {
      return await mobileMoneyWallet(payload.country, payload.network, payload.orderId, payload.data);
    },
    async chargeCardWallet (context, payload) {
      return await cardWallet(payload.country, payload.scheme, payload.orderId, payload.data);
    },
    async producePartialPaymentUpdateRequest (context, payload) {
      return await producePartialPaymentUpdateRequest(payload);
    },
    async changePaymentWallet (context, payload) {
      return await changePaymentMethod(payload);
    }
  },
  getters: {
    statusCheckData (state) {
      return state.statusCheckData;
    },
    getErrorMessage (state) {
      return state.errorMessage;
    }
  }
};

export default paymentModule;
