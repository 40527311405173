<template>
  <base-modal v-if="modalConfig.visible" @close="closeModal" :close-on-back-drop-clicked="false">
   <div class="text-center">
     <div class="mb-3">
       <img src="assets/images/failed.png" alt="logo" class="mt-3" width="90">
     </div>
     <div>
       <p> {{ modalConfig.message }}
       </p>
     </div>
     <div class="mt-4">
       <a role="button" class="btn btn-outline-primary" @click="closeModal">Close</a>
     </div>
   </div>
  </base-modal>
  <base-receipt-card v-if="!order">
    <div style="height: 500px"></div>
  </base-receipt-card>
  <base-receipt-card v-else>
    <div>
      <div class="mt-4 px-4" ref="myInvoice">
        <div class="stream--orders mb-0 border-0">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="info">
                <h6 class="font-weight-bold mb-0">{{ extraData.paylinkTitle }}</h6>
                <p class="text-muted mb-0">{{ extraData.customerMobileNumber }}</p>
                <p class="text-muted mb-0">{{ formatDate(order.orderDate) }}</p>
              </div>
            </div>
            <div class="">
              <img :src="extraData.logoUrl" alt="logo" class="img-fluid rounded-circle object-fit-contain" width="80" height="80">
            </div>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <div class="d-flex align-items-center">
              <div class="info">
                <p class=" text-muted mb-0">Order Number</p>
                <p class="text-muted mb-0" v-if="phoneNumber">Customer Number</p>
                <p class="text-muted mb-0" v-if="customerData">Customer Name </p>
              </div>
            </div>
            <div class="info text-right">
              <p class="text-muted mb-0">{{ order.orderNumber }}</p>
              <p class="text-muted mb-0" v-if="phoneNumber">{{ phoneNumber }}</p>
              <p class="text-muted mb-0" v-if="customerData">
                {{ customerData ? customerData.fullName : customerData.legalName }}</p>
            </div>
          </div>
        </div>
        <div class="saved-order">
          <table class="table fixed_header table-borderless">
            <thead class="border-bottom">
            <tr>
              <th scope="col" class="pr-0">Qty</th>
              <th scope="col" class="pr-0">Description</th>
              <th scope="col" class="pr-0 text-right">Amount</th>
            </tr>
            </thead>
            <tbody class="border-bottom" id="scroll">
            <tr v-for="(order, index) in order.orderItems" :key="index">
              <td>{{ order.quantity }}</td>
              <td>{{shortDescription(order.name) }}</td>
              <td><span class="currency">{{ order.currency }}</span>{{ $filters.formatAmount(order.unitPrice) }}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="2" class="text-muted text-right w-75">Processing Fee</td>
              <td><span class="currency">{{ order.currency }}</span>{{ $filters.formatAmount(charge) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="text-muted text-right w-75">Subtotal</td>
              <td><span class="currency">{{ order.currency }}</span>{{ $filters.formatAmount(order.subtotal) }}</td>
            </tr>
            <tr>
              <td colspan="2" class="font-wieght-bold text-right w-75">Total</td>
              <td><span class="currency">{{ order.currency }}</span>{{ $filters.formatAmount(totalAmount) }}</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="row align-items-center justify-content-center">
        <div class="pt-4 d-flex">
          <a :href="returnUrl" class="btn btn-outline-primary text-uppercase font-weight-bold mr-3" target="_blank">Continue</a>
          <button @click="downLoadReceipt" class="btn d-block btn-primary" type="button" :disabled="isDownloading"> <span class="fa fa-spin fa-spinner" v-if="isDownloading"> </span>  Download Receipt</button>
        </div>
      </div>
    </template>
  </base-receipt-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import BaseModal from '@/components/ui/BaseModal';
import { shortenText } from '@/utils';
import BaseReceiptCard from '@/components/ui/BaseReceiptCard';

export default {
  name: 'Index',
  components: { BaseReceiptCard, BaseModal },
  data () {
    return {
      isDownloading: false
    };
  },
  computed: {
    ...mapGetters({
      businessInfo: 'storeModule/businessInfo',
      order: 'storeModule/order',
      charge: 'chargeModule/getCharge',
      customerData: 'customersModule/customerData',
      phoneNumber: 'customersModule/phoneNumber',
      modalConfig: 'modalConfig'
    }),
    totalAmount () {
      if (!this.order?.totalAmountDue) {
        return 0;
      }
      return Number(this.order.totalAmountDue) + Number(this.charge);
    },
    extraData () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order.extraData);
    },
    returnUrl () {
      if (this.order.returnUrl) {
        return `${this.order.returnUrl}?checkoutid=${this.order.id}`;
      }
      return 'https://hubtel.com';
    }
  },
  methods: {
    ...mapActions({
      getStoreDetails: 'storeModule/getStoreDetails'
    }),
    formatDate (date) {
      if (!date) {
        return;
      }
      return dayjs(date).format('MMM DD, YYYY, hh:mm A');
    },
    closeModal () {
      this.$store.commit('setModalConfig', {
        visible: false,
        message: ''
      });
    },
    downLoadReceipt () {
      this.isDownloading = true;
      const element = this.$refs.myInvoice;
      const options = {
        margin: 0.5,
        filename: `Receipt No.:${this.order.orderNumber}.pdf`,
        image: { type: 'jpeg', quality: 1 },
        html2canvas: {
          scale: 2,
          dpi: 300,
          letterRendering: true,
          useCORS: true
        },
        jsPDF: { unit: 'mm', format: [120, 120], orientation: 'portrait' }
      };
      window.html2pdf().set(options).from(element).save().then(data => {
        this.isDownloading = false;
      }).catch(e => {
        this.isDownloading = false;
      });
    },
    shortDescription (text) {
      return shortenText(text);
    }
  },
  async created () {
    if (!this.order) {
      await this.getStoreDetails(this.order?.id);
    }
  }
};
</script>

<style scoped>

</style>
