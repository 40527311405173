import axios from 'axios';
import store from '@/store';
import { ClientJS } from 'clientjs';

const client = new ClientJS();
const fingerPrintData = JSON.stringify({
  fingerprint: client.getFingerprint(),
  os: client.getOS(),
  osVersion: client.getOSVersion(),
  device: client.getDevice(),
  deviceType: client.getDeviceType(),
  deviceVendor: client.getDeviceVendor(),
  browser: client.getBrowser(),
  browserVersion: client.getBrowserVersion(),
  browserMajorVersion: client.getBrowserMajorVersion(),
  currentResolution: client.getCurrentResolution()

});

const httpClient = axios.create({});
httpClient.interceptors.request.use(
  config => {
    const businessInfo = store.getters['storeModule/businessInfo'];
    const order = store.getters['storeModule/order'];
    const phoneNumber = store.getters['customersModule/phoneNumber'];
    const selectedMobileNetwork = store.getters.selectedMobileNetwork;
    const customerAuthToken = store.getters['customersModule/customerAuthToken'];

    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Auth-Token'] = customerAuthToken ?? '';
    config.headers['X-BusinessId'] = businessInfo?.id ?? '';
    config.headers['X-BusinessName'] = businessInfo?.name ?? '';
    config.headers['X-Country'] = businessInfo?.country ?? '';
    config.headers['X-CustomerNumber'] = phoneNumber ?? '';
    config.headers['X-Description'] = '';
    config.headers['X-Network'] = selectedMobileNetwork ?? '';
    config.headers['X-OrderId'] = order?.id ?? '';
    config.headers['X-FingerprintData'] = fingerPrintData;
    config.headers.Authorization = `Bearer ${process.env.VUE_APP_TOKEN}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
httpClient.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const response = error.response;
    if (response) {
      await handleError(response);
    }

    return Promise.reject(error);
  }
);

const handleError = async (error) => {
  if (error?.data?.message) {
    await store.dispatch('displayModal', {
      visible: true,
      message: error.data.message
    });
  } else {
    await store.dispatch('displayModal', {
      visible: true,
      message: 'Sorry, your request couldn\'t be processed. Our engineers have been alerted. Please try again in a few minutes.'
    });
  }
};

export default httpClient;
