<template>
   <RouterView />
</template>

<script>
import { LoggerFactory } from '@/utils/logging/loggerFactory';

export default {
  provide () {
    return {
      logger: LoggerFactory.create()
    };
  }
};
</script>

<style>

</style>
