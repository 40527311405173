<template>
  <div class="tab-pane text-center active" id="succesfulTab">
    <div>
      <base-header></base-header>
      <div class="mt-5 pt-4">
        <img src="assets/images/thumbs.png" alt="success" class="w-25">
      </div>
      <div class="px-3 pt-1">
        <div>
          <div>
            <h6 class="font-weight-bold mb-1">Payment was successful</h6>
            <p>You should receive a receipt on your phone with your order details in a moment</p>
          </div>
          <div class="d-flex flex-column-reverse mt-4" style="gap: 10px;">
            <a :href="returnUrl" class="btn btn-outline-primary text-uppercase font-weight-bold py-3" target="_blank">Continue</a>
            <a role="button" class="btn btn-primary text-uppercase font-weight-bold py-3" @click="viewReceipt">View Receipt</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/ui/BaseHeader';
import { mapGetters } from 'vuex';

export default {
  name: 'BasePaymentSuccess',
  components: { BaseHeader },
  computed: {
    ...mapGetters({
      order: 'storeModule/order'
    }),
    returnUrl () {
      if (this.order.returnUrl) {
        return `${this.order.returnUrl}?checkoutid=${this.order.id}`;
      }
      return 'https://hubtel.com';
    }
  },
  methods: {
    viewReceipt () {
      this.$store.commit('customersModule/setCustomerSession', {});
      this.$router.push({ name: 'Receipt' });
    }
  }
};
</script>

<style scoped>

</style>
