<template>
  <base-loader v-if="showLoader"></base-loader>
  <base-modal v-if="showFraudLabModal" @close="showFraudLabModal = false" :close-on-back-drop-clicked="false">
    <div class="text-center">
      <div class="mb-3">
        <img src="assets/images/pendingicon.svg" alt="" class="mt-3" width="90">
      </div>
      <div>
        <h6  class="font-weight-bold">Card Pending Review</h6>
        <p> {{ responseFraudLabMsg }}
        </p>
      </div>
      <div class="mt-4">
        <a role="button" class="btn btn-primary btn-block" @click="handleFraudLabCheckStatus">Check Status</a>
      </div>
    </div>
  </base-modal>
  <div class="tab-pane active" id="bankDetails">
    <div>
      <div class="pb-4 mb-2">
        <base-header></base-header>
      </div>
      <div class="">
        <h6 class="font-weight-bold mb-1">Pay with bank card</h6>
      </div>
      <div class="mt-2">
        <div>
          <div class="bank-card" id="scroll">
            <div class="mb-3">
              <label class="text-body mb-1">Card number</label>
              <div class="input-group border">
                <input type="text" class="form-control py-3 bg-white border-0 font-weight-bold" placeholder="Card number"
                       aria-label="Text input with dropdown button" ref="cardNumberInput" v-model="v$.cardNumber.$model"
                       v-cardformat:formatCardNumber>
                <div class="input-group-append">
                <span
                    class="input-group-text bg-white border-0" id="basic-addon1">
                     <img v-if="cardBrand === 'visa'" src="assets//images/visa.png" alt="card icon" width="25">
                     <img v-else-if="cardBrand === 'mastercard'" src="assets//images/mc.png" alt="card icon" width="25">
                      <img v-else src="assets//images/card_80942.png" alt="card icon" width="25">
                </span>
                </div>
              </div>
            </div>
            <div class="row no-gutters mb-3">
              <div class="col-6 pr-1">
                <div>
                  <label class="text-body mb-1">Expiry</label>
                  <input type="text" class="form-control py-3 bg-white font-weight-bold" placeholder="MM/YY"
                         ref="cardExpirationDateInput" v-model="v$.cardExpirationDate.$model"
                         v-cardformat:formatCardExpiry>
                </div>
              </div>
              <div class="col-6 pl-1">
                <div>
                  <label class="text-body mb-1">CVV</label>
                  <input type="text" class="form-control py-3 bg-white font-weight-bold" placeholder="CVV"
                         ref="cardCvvInput" v-model="v$.cardCvv.$model" v-cardformat:formatCardCVC>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label class="text-body mb-1">Full name on card</label>
              <div class="input-group border">
                <input type="text" class="form-control py-3 bg-white border-0 font-weight-bold" placeholder="Full name"
                       aria-label="Text input with dropdown button" ref="fullNameInput" v-model="v$.fullName.$model">
              </div>
            </div>
            <div class="mb-3">
              <label class="text-body mb-1">Phone number</label>
              <vue-tel-input v-model="phone" mode="international" styleClasses="form-control py-3 border shadow-none bg-white" @validate="validate" :inputOptions="inputOptions"></vue-tel-input>
            </div>
          </div>
          <div class="mt-3 pt-3 d-flex flex-column-reverse" style="gap: 10px;">
            <a role="button" @click="handleChangeComponent('BasePaymentChannels')"
               class="text-center mt-2 font-weight-bold text-uppercase">Cancel</a>
            <button type="button" @click="handleFraudLabRequest" :disabled="v$.$invalid || !isValid"
                    class="btn btn-primary text-uppercase font-weight-bold py-3">Pay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <iframe
      id="cardinal_collection_iframe"
      name="collectionIframe"
      height="10"
      width="10"
      style="display: none"
  ></iframe>
  <form
      id="cardinal_collection_form"
      method="POST"
      target="collectionIframe"
      :action="setupData.deviceDataCollectionUrl"
      ref="cardinal_collection_form"
  >
    <input
        id="cardinal_collection_form_input"
        type="hidden"
        name="JWT"
        ref="JWT"
        :value="setupData.accessToken"
    />
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoader from '@/components/ui/BaseLoader';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import BaseHeader from '@/components/ui/BaseHeader';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { setMixPanelData } from '@/utils';
import BaseModal from '@/components/ui/BaseModal';

export default {
  name: 'BankCardChannel',
  inject: ['logger'],
  components: { BaseHeader, BaseLoader, VueTelInput, BaseModal },
  setup () {
    return { v$: useVuelidate() };
  },
  data () {
    return {
      showLoader: true,
      cardinal: window.Cardinal,
      cardNumber: '',
      cardExpirationDate: '',
      cardCvv: '',
      cardBrand: '',
      cardDetails: null,
      fullName: '',
      questions: ['I\'m not able to submit payment request', 'My card payment is failing'],
      phone: null,
      customerPhoneNumber: null,
      isValid: false,
      inputOptions: {
        showDialCode: true,
        placeholder: 'Phone Number'
      },
      setupData: {
        deviceDataCollectionUrl: '',
        accessToken: '',
        hubtelReference: '',
        id: '',
        referenceId: '',
        status: ''
      },
      showFraudLabModal: false,
      responseFraudLabMsg: '',
      responseFraudLabId: '',
      reference3dsId: '',
      isEnrollmentCalled: false
    };
  },
  computed: {
    ...mapGetters({
      businessInfo: 'storeModule/businessInfo',
      order: 'storeModule/order',
      cyberSourceTokenData: 'cyberSourceModule/cyberSourceTokenData',
      orderId: 'orderId',
      statusCheckData: 'paymentModule/statusCheckData',
      isCustomerTrusted: 'storeModule/isCustomerTrusted',
      charge: 'chargeModule/getCharge'
    }),
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.paylinkDetails.paylinkUrl ?? '',
        customerName: this.paylinkDetails.customerName ?? '',
        title: this.paylinkDetails.paylinkTitle ?? '',
        description: this.paylinkDetails.paylinkDescription ?? '',
        phoneNumber: this.paylinkDetails.consumerSettlementNumber ?? this.paylinkDetails.customerMobileNumber,
        linkType: this.paylinkDetails.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.paylinkDetails.hasIdentifier ?? '',
        source: this.paylinkDetails.source ?? ''
      };
    }
  },
  validations () {
    return {
      cardNumber: {
        required
      },
      cardExpirationDate: {
        required
      },
      cardCvv: {
        required
      },
      fullName: {
        required,
        minLength: minLength(2)
      }
    };
  },
  watch: {
    cardNumber (newValue) {
      if (newValue && newValue.length === 19) {
        this.logger.debug(newValue?.length, 'Card Number length');
        this.$nextTick(() => this.$refs.cardExpirationDateInput.focus());
      }
    },
    cardExpirationDate (newValue) {
      if (newValue && newValue.length === 7) {
        this.logger.debug(newValue?.length, 'Card Expiration Date length');
        this.$nextTick(() => this.$refs.cardCvvInput.focus());
      }
    },
    cardCvv (newValue) {
      if (newValue && newValue.length === 3) {
        this.logger.debug(newValue?.length, 'full name length');
        this.$nextTick(() => this.$refs.fullNameInput.focus());
      }
    },
    cardBrand (value) {
      const cardBrands = ['visa', 'mastercard'];
      if (cardBrands.includes(value)) {
        this.getWalletCharge(value);
      } else {
        // reset charge
        this.$store.commit('chargeModule/setCharge', { fee: 0 });
      }
    }
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent',
      getCyberSourceToken: 'cyberSourceModule/getCyberSourceToken',
      displayModal: 'displayModal',
      validate3DSToken: 'cyberSourceModule/validate3DSToken',
      cyberSource3dsCheck: 'cyberSourceModule/cyberSource3dsCheck',
      sendFraudLabRequest: 'cyberSourceModule/sendFraudLabRequest',
      checkFraudLabStatus: 'cyberSourceModule/checkFraudLabStatus',
      send3DEnrollRequest: 'cyberSourceModule/send3DEnrollRequest',
      send3DSetupRequest: 'cyberSourceModule/send3DSetupRequest',
      send3dsEnrollmentRequest: 'cyberSourceModule/send3dsEnrollmentRequest',
      checkOrderStatus: 'paymentModule/checkOrderStatus',
      chargeCardDirect: 'paymentModule/chargeCardDirect',
      calculateCharges: 'chargeModule/calculateCharges'
    }),
    callbegin () {
      const orderObject = {
        Consumer: {
          Account: {
            AccountNumber: this.cardDetails.accountNo,
            ExpirationMonth: this.cardDetails.expiryMonth,
            ExpirationYear: this.cardDetails.expiryYear
          }
        }
      };

      this.cardinal.configure({
        logging: {
          debug: 'verbose'
        }
      });

      this.cardinal.setup('init', {
        jwt: this.cyberSourceTokenData.jwtToken,
        order: orderObject
      });

      this.cardinal.on('payments.setupComplete', (data) => {
        // For example, you may have your Submit button disabled on page load. Once you are setup
        // for CCA, you may then enable it. This will prevent users from submitting their order
        // before CCA is ready.
        this.logger.debug('setup completed');
        this.logger.debug(data);

        this.check3DS();
      });

      this.cardinal.on('payments.validated', (data) => {
        // For example, you may have your Submit button disabled on page load. Once you are setup
        // for CCA, you may then enable it. This will prevent users from submitting their order
        // before CCA is ready.
        this.logger.debug('payments validated');
        this.logger.debug('RESPONSE FROM payments.validated BELOW ', data);
        this.logger.debug(data);
        this.logger.debug('data.ErrorDescription', data.ErrorDescription);

        this.showLoader = false;

        if (data.ErrorNumber === 0) {
          this.validate3DS();
        } else {
          this.displayModal({
            visible: true,
            message: data.ErrorDescription
          });
        }
      });
    },
    async validate3DS () {
      try {
        const request = {
          cvc: this.cardDetails.cvv,
          referenceId: this.cyberSourceTokenData.referenceId,
          authenticationTransactionId: this.cardinal3DSResponse.is3dsResponse.captureReply.authenticationTransactionId,
          customerPhoneNumber: this.customerPhoneNumber
        };
        this.showLoader = true;
        const response = await this.validate3DSToken(request);
        this.showLoader = false;
        const responseData = response.data;
        if (responseData) {
          await this.checkPaymentStatus();
        } else {
          this.changeComponent('BasePaymentFailed');
        }
      } catch (e) {
        this.showLoader = false;
      }
    },
    async chargeCard (scheme) {
      try {
        const request = {
          customerPhoneNumber: this.customerPhoneNumber ?? this.order?.customerMobileNumber,
          referenceId: this.cyberSourceTokenData.referenceId,
          fullNameOnCard: this.fullName
        };
        this.showLoader = true;
        await this.chargeCardDirect({
          country: this.businessInfo.country,
          scheme: scheme,
          orderId: this.orderId,
          data: request
        });
        this.showLoader = false;
        this.changeComponent('BasePaymentPending');
      } catch (e) {
        this.showLoader = false;
      }
    },
    async checkPaymentStatus () {
      try {
        this.showLoader = true;
        await this.checkOrderStatus({
          orderId: this.orderId,
          businessId: this.businessInfo.id
        });
        this.showLoader = false;
        if (this.statusCheckData.data === 'Pending') {
          this.changeComponent('BasePaymentPending');
        } else if (this.statusCheckData.data === 'Failed') {
          this.changeComponent('BasePaymentFailed');
        } else if (this.statusCheckData.data === 'Success') {
          this.changeComponent('BasePaymentSuccess');
        }
      } catch (e) {
        this.showLoader = false;
      }
    },
    callcad (data) {
      this.logger.debug('les g');
      this.logger.debug(data.is3dsResponse.captureReply.acsURL);
      this.logger.debug(data.is3dsResponse.captureReply.paReq);
      this.logger.debug(data.is3dsResponse.captureReply.authenticationTransactionId);

      this.cardinal.continue('cca', {
        AcsUrl: data.is3dsResponse.captureReply.acsURL,
        Payload: data.is3dsResponse.captureReply.paReq
      },
      {
        OrderDetails: {
          TransactionId: data.is3dsResponse.captureReply.authenticationTransactionId
        }
      }
      );
    },
    async check3DS () {
      try {
        const request = {
          expiryMonth: this.cardDetails.expiryMonth,
          expiryYear: this.cardDetails.expiryYear,
          cvc: this.cardDetails.cvv,
          cardNumber: this.cardDetails.accountNo,
          currency: this.businessInfo.currency,
          country: this.businessInfo.country,
          jwtToken: this.cyberSourceTokenData.jwtToken,
          fullNameOnCard: this.fullName
        };
        this.showLoader = true;

        const response = await this.cyberSource3dsCheck(request);
        this.cardinal3DSResponse = response.data.data;

        if (this.cardinal3DSResponse.is3ds) {
          this.callcad(this.cardinal3DSResponse);
        } else {
          if (this.isCustomerTrusted) {
            await this.chargeCard(this.cardinal3DSResponse.scheme);
          } else {
            this.$store.commit('setModalConfig', {
              visible: true,
              message: 'This account requires a secure bank card to receive payments. Contact your bank to enable 3DS/VerifiedByVisa on your card.'
            });
            this.showLoader = false;
          }
        }
      } catch (e) {
        this.showLoader = false;
      }
    },
    pay () {
      const details = {};
      details.cvv = this.cardCvv;
      details.accountNo = this.cardNumber.replace(/\s/g, '');
      const expirySplit = this.cardExpirationDate.replace(/\s/g, '').split('/');
      details.expiryMonth = expirySplit[0];
      details.expiryYear = expirySplit[1];
      details.cardName = this.cardBrand;

      this.cardDetails = details;

      this.logger.debug(details);

      this.showLoader = true;
      setMixPanelData(this.mixPanelData, 'checkout_wallet_details_entered', 'checkout', { walletType: 'card', walletChannel: 'card' });
      this.callbegin();
    },
    async getWalletCharge (cardBrand) {
      const payload = {
        provider: 'cardnotpresent-' + cardBrand.toLowerCase(),
        type: 'CARD',
        orderId: this.order.id
      };
      await this.calculateCharges(payload);
    },

    validate (inputOptions) {
      this.isValid = inputOptions.valid;
      this.customerPhoneNumber = `${inputOptions.countryCallingCode}${inputOptions.nationalNumber}`;
    },
    handleChangeComponent (component) {
      setMixPanelData(this.mixPanelData, 'order_cancelled', 'checkout', { walletType: 'card', walletChannel: 'card' });
      this.changeComponent(component);
    },

    /* New 3DS flow for cards */
    async handleFraudLabRequest () {
      try {
        this.showLoader = true;
        const expirySplit = this.cardExpirationDate.replace(/\s/g, '').split('/');
        const expiryMonth = expirySplit[0];
        const expiryYear = expirySplit[1];
        const payload = {
          cvv: this.cardCvv,
          cardNumber: this.cardNumber.replace(/\s/g, ''),
          expiryMonth,
          expiryYear,
          customerPhoneNumber: this.phone.replace(/\s/g, ''),
          cardHolderName: this.fullName,
          orderId: this.orderId
        };
        const response = await this.sendFraudLabRequest(payload);
        this.showLoader = false;
        const { status, message, data } = response.data;
        this.responseFraudLabId = data?.requestId ?? '';
        this.fraudLabStatus(status, message);
      } catch (e) {
        this.showLoader = false;
        this.displayModal({
          visible: true,
          message: e?.response?.data?.message ?? 'An error occured. Try again.'
        });
      }
    },

    async handleFraudLabCheckStatus () {
      try {
        this.showLoader = true;
        this.showFraudLabModal = false;
        const response = await this.checkFraudLabStatus(this.responseFraudLabId);
        this.showLoader = false;
        const { status, message } = response.data;
        this.fraudLabStatus(status, message);
      } catch (e) {
        this.showLoader = false;
        this.displayModal({
          visible: true,
          message: e?.response?.data?.message ?? 'An error occured. Try again.'
        });
      }
    },

    generatePayload () {
      const expirySplit = this.cardExpirationDate.replace(/\s/g, '').split('/');
      const expiryMonth = expirySplit[0];
      const expiryYear = expirySplit[1];
      return {
        hubtelReference: this.orderId,
        cardNumber: this.cardNumber.replace(/\s/g, ''),
        expiryMonth,
        expiryYear: `20${expiryYear}`,
        cvc: this.cardCvv,
        amount: Number(this.order.subtotal),
        origin: this.setupData.deviceDataCollectionUrl,
        id: this.setupData.id,
        referenceId: this.setupData.referenceId,
        accessToken: this.setupData.accessToken
      };
    },

    async start3DsFlow () {
      try {
        this.showLoader = true;
        const cardData = this.generatePayload();
        window.addEventListener('message', this.enroll3ds, false);
        const response = await this.send3DSetupRequest(cardData);
        Object.assign(this.setupData, response.data.data);
        const cardinalCollectionForm = this.$refs.cardinal_collection_form;
        this.$refs.JWT.value = this.setupData.accessToken;
        cardinalCollectionForm.action = this.setupData.deviceDataCollectionUrl;
        if (cardinalCollectionForm) {
          cardinalCollectionForm.submit();
        }
      } catch (e) {
        this.$store.commit('setModalConfig', {
          visible: true,
          message: 'This account requires a secure bank card to receive payments. Contact your bank to enable 3DS/VerifiedByVisa on your card.'
        });
        this.showLoader = false;
      }
    },

    async enroll3ds (event) {
      if (this.isEnrollmentCalled) return;
      try {
        const dataPayload = this.generatePayload();
        if (event.origin === 'https://centinelapi.cardinalcommerce.com') {
          window.removeEventListener('message', this.enroll3ds, false);
          const response = await this.send3DEnrollRequest(dataPayload);
          const data = response.data.data;
          this.reference3dsId = data.hubtelReference;
          const payload = {
            Account: {
              AccountNumber: dataPayload.cardNumber,
              ExpirationMonth: dataPayload.expiryMonth,
              ExpirationYear: dataPayload.expiryYear
            },

            jwt: data.jwt,

            AcsUrl: data.acsUrl,
            Payload: data.pareq,
            authenticationTransactionId: data.authenticationTransactionId
          };
          this.initializeCybersource(payload);
        }
      } catch (e) {
        this.$store.commit('setModalConfig', {
          visible: true,
          message: 'This account requires a secure bank card to receive payments. Contact your bank to enable 3DS/VerifiedByVisa on your card.'
        });
        this.showLoader = false;
      }
    },

    initializeCybersource (payload) {
      window.Cardinal.configure({
        logging: {
          debug: 'verbose'
        }
      });

      const orderObject = {
        Consumer: {
          Account: payload.Account
        }
      };

      window.Cardinal.setup('init', {
        jwt: payload.jwt,
        order: orderObject
      });

      this.showLoader = false;

      window.Cardinal.on('payments.setupComplete', (data) => {
        this.popupOTPModal(payload);
      });

      window.Cardinal.on('payments.validated', (data) => {
        // For example, you may have your Submit button disabled on page load. Once you are setup
        // for CCA, you may then enable it. This will prevent users from submitting their order
        // before CCA is ready.
        if (data.Payment.ExtendedData.ChallengeCancel) {
          window.removeEventListener('message', this.enroll3ds, false);
          this.handleChangeComponent('BasePaymentChannels');
          return;
        }

        if (!(data.ErrorNumber === 0)) {
          this.displayModal({
            visible: true,
            message: data.ErrorDescription
          });
          return;
        }
        this.checkout();
      });
    },
    async checkout () {
      try {
        this.showLoader = true;
        const payload = {
          referenceId: this.reference3dsId,
          customerPhoneNumber: this.phone.replace(/\s/g, ''),
          cvv: this.cardCvv
        };
        await this.send3dsEnrollmentRequest(payload);
        this.showLoader = false;
        await this.checkPaymentStatus();
      } catch (e) {
        this.showLoader = false;
      }
    },
    popupOTPModal (payload) {
      this.isEnrollmentCalled = true;
      window.Cardinal.continue(
        'cca',
        {
          AcsUrl: payload.AcsUrl,
          Payload: payload.Payload
        },
        {
          OrderDetails: {
            TransactionId: payload.authenticationTransactionId
          }
        }
      );
    },
    fraudLabStatus (status, message) {
      switch (status?.toLowerCase()) {
        case 'review':
          this.showFraudLabModal = true;
          this.responseFraudLabMsg = message;
          break;
        case 'rejected':
          this.displayModal({
            visible: true,
            message
          });
          break;
        case 'approved':
          this.start3DsFlow();
          break;
      }
    }
  },
  created () {
    this.showLoader = false;
    this.$store.commit('setCurrentPaymentChannel', 'Card');
  }
};
</script>

<style scoped>

</style>
