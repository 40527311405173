import { calculateCharges } from '@/api/charges';

const chargeModule = {
  namespaced: true,
  state () {
    return {
      fee: 0
    };
  },
  mutations: {
    setCharge (state, { fee }) {
      state.fee = fee;
    }
  },
  actions: {
    async calculateCharges (context, payload) {
      const response = await calculateCharges(payload.provider, payload.type, payload.orderId);
      context.commit('setCharge', response.data.data);
    }
  },
  getters: {
    getCharge (state) {
      return state.fee;
    }
  }
};

export default chargeModule;
