<template>
  <div class="tab-pane text-center active" id="succesfulTab">
    <div>
      <base-header></base-header>
      <div class="mt-5 pt-4">
        <img src="assets/images/thumbs.png" alt="success" class="w-25">
      </div>
      <div class="px-3 pt-1">
        <div>
          <div>
            <h6 class="font-weight-bold mb-1">Thank you!</h6>
            <p>You should receive an SMS receipt on your phone with your payment details if your payment was successful.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeader from '@/components/ui/BaseHeader';
import { setMixPanelData } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'BaseUntrustedSuccess',
  components: { BaseHeader },
  computed: {
    ...mapGetters({
      order: 'storeModule/order',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    }),
    extraData () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.extraData.paylinkUrl ?? '',
        customerName: this.extraData.customerName ?? '',
        title: this.extraData.paylinkTitle ?? '',
        description: this.extraData.paylinkDescription ?? '',
        phoneNumber: this.extraData.consumerSettlementNumber ?? this.extraData.customerMobileNumber,
        linkType: this.extraData.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.extraData.hasIdentifier ?? '',
        source: this.extraData.source ?? ''
      };
    }
  },
  created () {
    setMixPanelData(this.mixPanelData, 'order_paid', 'checkout', { walletType: 'momo', walletChannel: 'momo' });
  }
};
</script>

<style scoped>

</style>
