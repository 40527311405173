<template>
  <base-loader v-if="showLoader"></base-loader>
  <div class="tab-pane active" id="partnerBanks">
    <div v-if="!isSuccessful">
      <div class="pb-4 mb-2">
      <base-header></base-header>
      </div>
      <div class="">
        <h6 class="font-weight-bold mb-1">Works perfectly with apps from</h6>
      </div>
      <div class="">
        <div>
          <div class="my-4">
            <img src="assets/images/ghqr/bankhubtel-small.png" alt="ghrpartners" class="w-100">
          </div>
          <div class="mb-3">
            <label class="text-body mb-1">Phone number</label>
            <vue-tel-input v-model="phone" mode="international" styleClasses="form-control py-3 border shadow-none bg-white" @validate="validate" :inputOptions="inputOptions"></vue-tel-input>
          </div>
          <div class="mt-3 pt-3 d-flex flex-column-reverse" style="gap: 10px;">
            <a role="button" @click="handleChangeComponent('BasePaymentChannels')" class="text-center mt-2 font-weight-bold text-uppercase">Cancel</a>
            <button type="button" @click="generateQrCode" class="btn btn-primary text-uppercase font-weight-bold py-3" :disabled="!isValid">Generate Code</button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" v-else>
      <div class="pb-4 mb-2">
        <base-header></base-header>
      </div>
      <div class="">
        <h6 class="font-weight-bold m-0">Scan QR code below to pay with your bank or Hubtel App</h6>
      </div>
      <div>
        <div>
          <div>
            <img :src="ghQr.data" alt="qrcode">
          </div>
          <div>
            <base-check-payment-status></base-check-payment-status>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseLoader from '@/components/ui/BaseLoader';
import BaseCheckPaymentStatus from '@/components/ui/BaseCheckPaymentStatus';
import BaseHeader from '@/components/ui/BaseHeader';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { setMixPanelData } from '@/utils';
export default {
  name: 'GhQrChannel',
  components: { BaseHeader, BaseCheckPaymentStatus, BaseLoader, VueTelInput },
  data () {
    return {
      isSuccessful: false,
      showLoader: false,
      showPaymentPendingModal: false,
      showPaymentSuccessModal: false,
      showPaymentFailedModal: false,
      phone: null,
      customerPhoneNumber: null,
      isValid: false,
      inputOptions: {
        showDialCode: true,
        placeholder: 'Phone Number'
      }
    };
  },
  computed: {
    ...mapGetters({
      order: 'storeModule/order',
      ghQr: 'ghqrModule/getGhqr',
      isCustomerTrusted: 'storeModule/isCustomerTrusted'
    }),
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    },
    mixPanelData () {
      return {
        isCustomerTrusted: this.isCustomerTrusted,
        paylinkUrl: this.paylinkDetails.paylinkUrl ?? '',
        customerName: this.paylinkDetails.customerName ?? '',
        title: this.paylinkDetails.paylinkTitle ?? '',
        description: this.paylinkDetails.paylinkDescription ?? '',
        phoneNumber: this.paylinkDetails.consumerSettlementNumber ?? this.paylinkDetails.customerMobileNumber,
        linkType: this.paylinkDetails.linkType ?? '',
        totalAmountDue: this.order?.totalAmountDue ?? '',
        hasIdentifier: this.paylinkDetails.hasIdentifier ?? '',
        source: this.paylinkDetails.source ?? ''
      };
    }
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent',
      generateGhqr: 'ghqrModule/generateGhqr',
      calculateCharges: 'chargeModule/calculateCharges'
    }),

    async generateQrCode () {
      try {
        this.showLoader = true;
        const payload = {
          customerPhoneNumber: this.customerPhoneNumber,
          orderId: this.order.id
        };
        await this.generateGhqr(payload);
        this.isSuccessful = true;
        this.showLoader = false;
        setMixPanelData(this.mixPanelData, 'checkout_wallet_details_entered', 'checkout', { walletType: 'ghqr', walletChannel: 'ghqr' });
      } catch (e) {
        if (e.response) {
          setMixPanelData(this.mixPanelData, 'order_payment_failed', 'checkout', { walletType: 'ghqr', walletChannel: 'ghqr', reason: e.response.data.message });
        }
        this.showLoader = false;
      }
    },

    validate (inputOptions) {
      this.isValid = inputOptions.valid;
      this.customerPhoneNumber = `${inputOptions.countryCallingCode}${inputOptions.nationalNumber}`;
    },

    handleChangeComponent (component) {
      setMixPanelData(this.mixPanelData, 'order_cancelled', 'checkout', { walletType: 'ghqr', walletChannel: 'ghqr' });
      this.changeComponent(component);
    }
  },
  async created () {
    this.$store.commit('setCurrentPaymentChannel', 'Ghqr');
    try {
      this.showLoader = true;
      const payload = {
        provider: 'ghqr',
        type: 'ghqr',
        orderId: this.order.id
      };
      await this.calculateCharges(payload);
      this.showLoader = false;
    } catch (e) {
      this.showLoader = false;
    }
  }
};
</script>

<style scoped>
</style>
