<template>
  <div>
    <div class="position-absolute w-100 h-100 rounded" id="loading"
         style="top:0; left:0; background:rgba(255,255,255,0.8); z-index:200;">
      <div class="position-relative w-100 h-100">
        <div
            style="position:absolute; text-align:center; width:100%; top: 50%; -webkit-transform: translateY(-50%); -ms-transform: translateY(-50%); transform: translateY(-50%);">
          <div class="text-center">
            <img src="assets/images/hubtel-loader.gif" width="100"/>
          </div>

          <strong>{{ title }}</strong>
          <br>{{ description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    title: {
      type: String,
      default: 'Please wait...'
    },
    description: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>

</style>
