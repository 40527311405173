<template>
  <div class="form-control d-flex justify-content-between align-items-center p-2" v-if="businessInfo">
    <div>
      <img :src="paylinkDetails.logoUrl" alt="logo" class="rounded-circle object-fit-contain" width="50" height="50">
    </div>
    <div class="text-right">
      <p class="text-muted m-0 small">{{businessInfo.currency}} <span class="font-weight-bold text-body">{{$filters.formatAmount(total)}}</span></p>
      <p class="m-0 small">Requested by <span class="font-weight-bold">{{paylinkDetails.customerName}}</span></p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BaseHeader',
  computed: {
    ...mapGetters({
      order: 'storeModule/order',
      businessInfo: 'storeModule/businessInfo',
      charge: 'chargeModule/getCharge'
    }),
    total () {
      if (!this.order) { return 0; }
      return Number(this.order.totalAmountDue) + Number(this.charge);
    },
    paylinkDetails () {
      if (!this.order?.extraData) {
        return {};
      }
      return JSON.parse(this.order?.extraData);
    }
  }
};
</script>

<style scoped>

</style>
