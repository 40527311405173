<template>
  <div class="tab-pane text-center active" id="confirmPayment">
    <div>
      <base-header></base-header>
      <div class="mt-5">
        <img src="assets/images/hubtel-loader.gif" alt="gifloader" width="50">
      </div>
      <div class="mt-4">
        <div>
          <div>
            <p>A bill prompt has been sent to you on {{phoneNumber}}. Please authorize payment</p>
          </div>
        </div>
        <div>
          <div class="mt-5 pt-4">
            <base-check-payment-status v-if="showPaidButton"></base-check-payment-status>
          </div>
          <div>
            <div class="text-center text-muted mt-3">
              <p v-if="selectedMobileNetwork?.toLowerCase() === 'mtn-gh'">No prompt? Dial *170#, select My Wallet, then Approvals to complete this transaction.</p>
              <p v-if="selectedMobileNetwork?.toLowerCase() === 'vodafone-gh'">No prompt? Dial *110#, select Pay Bill, then type of bill you want to pay to complete this transaction.</p>
              <p v-if="selectedMobileNetwork?.toLowerCase() === 'tigo-gh'">No prompt? Dial *110#, select Approve, then type of bill you want to pay to complete this transaction.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseCheckPaymentStatus from '@/components/ui/BaseCheckPaymentStatus';
import BaseHeader from '@/components/ui/BaseHeader';

export default {
  name: 'MobileMoneyBillPrompt',
  components: { BaseHeader, BaseCheckPaymentStatus },
  data () {
    return {
      showPaidButton: false,
      questions: ['I’m not able to check payment status']
    };
  },
  computed: {
    ...mapGetters({
      phoneNumber: 'customersModule/phoneNumber',
      businessInfo: 'storeModule/businessInfo',
      order: 'storeModule/order',
      selectedMobileNetwork: 'selectedMobileNetwork'
    })
  },
  methods: {
    ...mapActions({
      changeComponent: 'changeComponent'
    }),
    renderPaidButton () {
      setTimeout(() => {
        this.showPaidButton = true;
      }, 5000);
    }
  },
  created () {
    this.renderPaidButton();
  }
};
</script>

<style scoped>

</style>
